import React, { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navigation from "./Navigation"
import { Row, Col, Modal } from "react-bootstrap"
//import aboutimg from "./Images/SSIPMT_logo.png"
// import aboutimg from "./Images/SSIPMT_Idea_lab.png"
// import mission from "./Images/mission.png"
// import vision from "./Images/vision.png"
import bootcampimg from "./Images/Bootcamp.png"
import fdpimg from "./Images/FDP.png"
import openschoolimg from "./Images/Open school.png"
import skillingprogramimg from "./Images/Skilling program.png"
import Workshopimg from "./Images/Workshop.jpg"
import professionalskiilingimg from "./Images/professionalskiiling.png"
import drone1 from "./Images/Events/Drone training/Drone1.jpg"
import drone2 from "./Images/Events/Drone training/Drone2.jpg"
import drone3 from "./Images/Events/Drone training/Drone3.jpg"
import drone4 from "./Images/Events/Drone training/Drone4.jpg"
import drone5 from "./Images/Events/Drone training/Drone5.jpg"
import drone6 from "./Images/Events/Drone training/Drone6.jpg"
import drone7 from "./Images/Events/Drone training/Drone7.jpg"
import drone8 from "./Images/Events/Drone training/Drone8.jpg"
import drone9 from "./Images/Events/Drone training/Drone9.jpeg"
import drone10 from "./Images/Events/Drone training/Drone10.jpeg"
import drone11 from "./Images/Events/Drone training/Drone11.jpeg"
import fdp3dprint1 from "./Images/Events/FDP on 3D Printing/IMG_2951.jpg"
import fdp3dprint2 from "./Images/Events/FDP on 3D Printing/IMG_2959.jpg"
import fdp3dprint3 from "./Images/Events/FDP on 3D Printing/IMG_3015.jpg"
import fdp3dprint4 from "./Images/Events/FDP on 3D Printing/IMG_3029.jpg"
import p2p1 from "./Images/Events/Pathshala to Prayogshala/p2p1.jpg"
import p2p2 from "./Images/Events/Pathshala to Prayogshala/p2p2.jpg"
import p2p3 from "./Images/Events/Pathshala to Prayogshala/p2p3.jpg"
import p2p4 from "./Images/Events/Pathshala to Prayogshala/p2p4.jpeg"
import p2p5 from "./Images/Events/Pathshala to Prayogshala/p2p5.jpg"
import p2p6 from "./Images/Events/Pathshala to Prayogshala/p2p6.jpg"
import img3dprint1 from "./Images/Events/Skilling Program/3D Printing/img3dprint1.jpeg"
import img3dprint2 from "./Images/Events/Skilling Program/3D Printing/img3dprint2.jpeg"
import img3dprint3 from "./Images/Events/Skilling Program/3D Printing/img3dprint3.jpeg"
import img3dprint4 from "./Images/Events/Skilling Program/3D Printing/img3dprint4.jpeg"
import img3dprint5 from "./Images/Events/Skilling Program/3D Printing/img3dprint5.jpeg"
import img3dprint6 from "./Images/Events/Skilling Program/3D Printing/img3dprint6.jpeg"
import appdev1 from "./Images/Events/Skilling Program/App Development/appdev1.jpg"
import appdev2 from "./Images/Events/Skilling Program/App Development/appdev2.jpg"
import appdev3 from "./Images/Events/Skilling Program/App Development/appdev3.jpg"
import appdev4 from "./Images/Events/Skilling Program/App Development/appdev4.jpg"
import appdev5 from "./Images/Events/Skilling Program/App Development/appdev5.jpg"
import appdev6 from "./Images/Events/Skilling Program/App Development/appdev6.jpg"
import appdev7 from "./Images/Events/Skilling Program/App Development/appdev7.jpg"
import Arduino1 from "./Images/Events/Skilling Program/Arduino/Arduino1.jpg"
import Arduino2 from "./Images/Events/Skilling Program/Arduino/Arduino2.jpg"
import Arduino3 from "./Images/Events/Skilling Program/Arduino/Arduino3.jpg"
import Arduino4 from "./Images/Events/Skilling Program/Arduino/Arduino4.jpg"
import Arduino5 from "./Images/Events/Skilling Program/Arduino/Arduino5.jpg"
import Arduino6 from "./Images/Events/Skilling Program/Arduino/Arduino6.jpg"
import Arduino7 from "./Images/Events/Skilling Program/Arduino/Arduino7.jpg"
import cbe1 from "./Images/Events/Skilling Program/C and Basic ELectronics/c&be1.jpeg"
import cbe2 from "./Images/Events/Skilling Program/C and Basic ELectronics/c&be2.jpeg"
import cbe3 from "./Images/Events/Skilling Program/C and Basic ELectronics/c&be3.jpeg"
import cbe4 from "./Images/Events/Skilling Program/C and Basic ELectronics/c&be4.jpeg"
// import dronedesign1 from "./Images/Events/Skilling Program/Drone Design/dronedesign1.jpeg"
// import dronedesign2 from "./Images/Events/Skilling Program/Drone Design/dronedesign2.jpeg"
// import dronedesign3 from "./Images/Events/Skilling Program/Drone Design/dronedesign3.jpeg"
// import dronedesign4 from "./Images/Events/Skilling Program/Drone Design/dronedesign4.jpg"
// import dronedesign5 from "./Images/Events/Skilling Program/Drone Design/dronedesign5.jpg"
// import dronedesign6 from "./Images/Events/Skilling Program/Drone Design/dronedesign6.jpg"
import ic1 from "./Images/Events/Skilling Program/IC Design/ic1.jpeg"
import ic2 from "./Images/Events/Skilling Program/IC Design/ic2.jpeg"
import ic3 from "./Images/Events/Skilling Program/IC Design/ic3.jpeg"
// import ic4 from "./Images/Events/Skilling Program/IC Design/ic4.jpeg"
import iot1 from "./Images/Events/Skilling Program/IoT/iot1.jpg"
import iot2 from "./Images/Events/Skilling Program/IoT/iot2.jpg"
import iot3 from "./Images/Events/Skilling Program/IoT/iot3.jpg"
import iot4 from "./Images/Events/Skilling Program/IoT/iot4.jpg"
import iot5 from "./Images/Events/Skilling Program/IoT/iot5.jpg"
import pcb1 from "./Images/Events/Skilling Program/PCB Designing/pcb1.jpeg"
import pcb2 from "./Images/Events/Skilling Program/PCB Designing/pcb2.jpeg"
import pcb3 from "./Images/Events/Skilling Program/PCB Designing/pcb3.jpeg"
import pcb4 from "./Images/Events/Skilling Program/PCB Designing/pcb4.jpeg"
import pcb5 from "./Images/Events/Skilling Program/PCB Designing/pcb5.jpeg"
import pcb6 from "./Images/Events/Skilling Program/PCB Designing/pcb6.jpeg"
import pcb7 from "./Images/Events/Skilling Program/PCB Designing/pcb7.jpeg"
import pcb8 from "./Images/Events/Skilling Program/PCB Designing/pcb8.jpeg"
import RaspberryPi1 from "./Images/Events/Skilling Program/Raspberry Pi/RaspberryPi1.jpg"
import RaspberryPi2 from "./Images/Events/Skilling Program/Raspberry Pi/RaspberryPi2.jpg"
import RaspberryPi3 from "./Images/Events/Skilling Program/Raspberry Pi/RaspberryPi3.jpg"
import RaspberryPi4 from "./Images/Events/Skilling Program/Raspberry Pi/RaspberryPi4.jpg"
import RaspberryPi5 from "./Images/Events/Skilling Program/Raspberry Pi/RaspberryPi5.png"
import droneboot1 from "./Images/Events/Skilling Program/Bootcamps/Drone/droneboot1.jpg"
import droneboot2 from "./Images/Events/Skilling Program/Bootcamps/Drone/droneboot2.jpg"
import droneboot3 from "./Images/Events/Skilling Program/Bootcamps/Drone/droneboot3.jpg"
import droneboot4 from "./Images/Events/Skilling Program/Bootcamps/Drone/droneboot4.jpg"
import droneboot5 from "./Images/Events/Skilling Program/Bootcamps/Drone/droneboot5.jpg"
import droneboot6 from "./Images/Events/Skilling Program/Bootcamps/Drone/droneboot6.jpg"
import droneboot7 from "./Images/Events/Skilling Program/Bootcamps/Drone/droneboot7.jpg"
import MET1 from "./Images/Events/Skilling Program/Bootcamps/METool/MET1.jpeg"
import MET2 from "./Images/Events/Skilling Program/Bootcamps/METool/MET2.jpeg"
import MET3 from "./Images/Events/Skilling Program/Bootcamps/METool/MET3.jpeg"
import MET4 from "./Images/Events/Skilling Program/Bootcamps/METool/MET4.jpeg"
import MET5 from "./Images/Events/Skilling Program/Bootcamps/METool/MET5.jpeg"
import MET6 from "./Images/Events/Skilling Program/Bootcamps/METool/MET6.jpeg"
import MET7 from "./Images/Events/Skilling Program/Bootcamps/METool/MET7.jpeg"
import proskill1 from "./Images/Events/Professional Skilling Programs/proskill1.jpeg"
import proskill2 from "./Images/Events/Professional Skilling Programs/proskill2.jpeg"
import proskill3 from "./Images/Events/Professional Skilling Programs/proskill3.jpeg"
import proskill4 from "./Images/Events/Professional Skilling Programs/proskill4.jpeg"
import proskill5 from "./Images/Events/Professional Skilling Programs/proskill5.jpeg"
import proskill6 from "./Images/Events/Professional Skilling Programs/proskill6.jpeg"
import proskill7 from "./Images/Events/Professional Skilling Programs/proskill7.jpg"
import proskill8 from "./Images/Events/Professional Skilling Programs/proskill8.jpeg"
import proskill9 from "./Images/Events/Professional Skilling Programs/proskill9.jpeg"
import proskill10 from "./Images/Events/Professional Skilling Programs/proskill10.jpeg"
import openday1 from "./Images/Events/Open Day/openday1.jpg"
import openday2 from "./Images/Events/Open Day/openday2.jpg"
import openday3 from "./Images/Events/Open Day/openday3.jpeg"
import openday4 from "./Images/Events/Open Day/openday4.jpeg"
import openday5 from "./Images/Events/Open Day/openday5.jpg"
import openday6 from "./Images/Events/Open Day/openday6.jpg"
import openday7 from "./Images/Events/Open Day/openday7.jpg"
import openday8 from "./Images/Events/Open Day/openday8.jpg"
import openday9 from "./Images/Events/Open Day/openday9.jpg"
import openday10 from "./Images/Events/Open Day/openday10.JPG"
import openday11 from "./Images/Events/Open Day/openday11.JPG"
import openday12 from "./Images/Events/Open Day/openday12.jpeg"
import openday13 from "./Images/Events/Open Day/openday13.jpeg"
import openday14 from "./Images/Events/Open Day/openday14.jpg"
import openday15 from "./Images/Events/Open Day/openday15.jpg"
import openday16 from "./Images/Events/Open Day/openday16.jpg"
import openday17 from "./Images/Events/Open Day/openday17.jpg"
import openday18 from "./Images/Events/Open Day/openday18.jpeg"
import idea1_1 from "./Images/Events/Ideation Workshop/Idea-1/idea1_1.jpeg"
import idea1_2 from "./Images/Events/Ideation Workshop/Idea-1/idea1_2.jpg"
import idea2_1 from "./Images/Events/Ideation Workshop/Idea-2/idea2_1.jpeg"
import idea2_2 from "./Images/Events/Ideation Workshop/Idea-2/idea2_2.jpeg"
import idea2_3 from "./Images/Events/Ideation Workshop/Idea-2/idea2_3.jpeg"
import idea3_1 from "./Images/Events/Ideation Workshop/Idea-3/idea3_1.jpg"
import idea3_2 from "./Images/Events/Ideation Workshop/Idea-3/idea3_2.jpg"
import idea4_1 from "./Images/Events/Ideation Workshop/Idea-4/idea4_1.jpeg"
import idea4_2 from "./Images/Events/Ideation Workshop/Idea-4/idea4_2.jpeg"

// import openday19 from "./Images/Events/Open Day/openday19.jpeg"

import { FaCheck } from "react-icons/fa"

const Events2022 = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const skillingrogram = useRef()
    const fdp = useRef()
    const boot = useRef()
    const professional = useRef()
    const openday = useRef()

    const [skillingprog, setSkillingprog] = useState(false)
    const [faculty, setFaculty] = useState(false)
    const [bootcamp, setBootcamp] = useState(false)
    const [ideation, setIdeation] = useState(false)
    const [proskill, setProskill] = useState(false)
    const [open, setOpen] = useState(false)




    return (

        <div className="">

            <Modal
                size="xl"
                show={skillingprog}
                onHide={() => setSkillingprog(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered

                className=" justify-content-center"
            >
                <Modal.Header closeButton></Modal.Header>
                <Row style={{ backgroundColor: "#fff" }}>
                    <Row className="mt-5" >
                        <Col xs={1}></Col>
                        <Col >
                            <Row className="event2000thead justify-content-center">
                                Skilling Program
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The Skilling Program, organized by The IDEA Lab at SSIPMT is an endeavor to equip the students with the knowledge of latest technologies. IDEA Lab would serve as an infrastructure for students to take up and promote multidisciplinary education and research. Accordingly, students would be encouraged to get trained in these Labs and strive for creating problems/ projects/ internships in their own subjects/ disciplines.
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                Following are some of the area in which skilling programs are done for students of different disciplines of various colleges-
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col className="eventlisttext justify_content_left" >Drone Design and Development</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Internet of Things (IoT)</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Arduino</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Raspberry Pi</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >App Development</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >3D Printing</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >PCB Designing</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >C and Basic Electronics</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >IC Design Front End Approach</Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs className=''>
                            <Row className="homfinmgmtsubhead " >
                                Drone Design and Development
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The basic objective of this skilling program is to train, personnel having skill set of drone pilots who can assemble drones on their own.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={drone1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone7} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone8} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone9} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone10} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone11} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                Internet of Things (IoT)
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of the Training Program is to familiarize the students with the concept of Internet of Things and Application Development with more focus on Practice sessions on ARDUINO IDE and ANDROID STUDIO.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={iot1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={iot2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={iot3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={iot4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={iot5} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                Arduino
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of this skilling program is to familiarize students with Arduino as IDE, programming language & platform and to provide knowledge of Arduino boards and basic components and sensors.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino7} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                Raspberry Pi Programming with Python
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of the Skilling Program is to familiarize the faculties/students with basic Python Programming Language and the concept related to Internet of Things. Faculties/Students will get hands-on experience in working with Raspberry Pi and exploring IoT.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={RaspberryPi1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={RaspberryPi2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={RaspberryPi3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={RaspberryPi4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={RaspberryPi5} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                App Development using React Native
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                In this React Native training class, attendees learn how to use React Native to create cross-platform native apps quickly and easily. Students start from scratch and build up to a comprehensive app that uses modern techniques. In addition, participants learn best practices to consume RESTful data from a NodeJS/Express server and present it to the user in a multi-screen, interactive app.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev7} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                3D Printing
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of the Skilling Program is to familiarize the students with the concept of 3D Printing and Design with more focus on Practice session on CAD Software and Assembly of 3D printer, Troubleshooting of 3D Printers.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint6} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                PCB Designing
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of the workshop is to familiarize the students with the concept of Electronic Components and PCB Designing with more focus on Practice session on Eagle Software and Designing of PCB.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb7} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb8} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                C and Basic Electronics
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The major objective is to provide students with understanding of code organization and functional hierarchical decomposition with using complex data types. This course provides the student with the fundamental skills to understand the basic of semiconductor and components like diode, transistors and various types of Sensors.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={cbe1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={cbe2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={cbe3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={cbe4} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                IC Design Front End Approach
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of this skilling program is to make students understand the basics of IC design using HDL and also Simulation of circuits using Xilinx.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={ic1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={ic2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={ic3} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Row>
            </Modal>

            <Modal
                size="xl"
                show={faculty}
                onHide={() => setFaculty(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered

                className=" justify-content-center"
            >
                <Modal.Header closeButton></Modal.Header>
                <Row style={{ backgroundColor: "#fff" }}>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="event2000thead justify-content-center">
                                Faculty Development Program
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                Faculty needs to be trained on equipment, to be able to guide students and conduct events- including those from other institutions.
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                Following are some of the area in which faculty development programs are done-
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Faculty Development Program on Pathshaala to Prayogshaala</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Faculty Development program on 3D Printing</Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead ">
                                Faculty Development Program on Pathshaala to Prayogshaala
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The main objective of this FDP is to put "Theory into Practice" with the intent to impart practical knowledge to help young learners develop something new of their one with the help of their teachers. This will enhance the spirit of Ideation and innovation in the students.
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                This program was conducted for School teachers. 31 Schools Teachers from various schools participated in this program. They all were trained on the Science experimental kits developed by the SSIPMT IDEA Lab.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={p2p1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={p2p2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={p2p3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={p2p4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={p2p5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={p2p6} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead ">
                                Faculty Development program on 3D Printing
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of this Faculty Development Program is to familiarize the faculty with the concept of 3D Printing and Design with more focus on Practice session on CAD Software and Assembly of 3D printer, Troubleshooting of 3D Printers.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={fdp3dprint1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={fdp3dprint2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={fdp3dprint3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={fdp3dprint4} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Row>
            </Modal>

            <Modal
                size="xl"
                show={ideation}
                onHide={() => setIdeation(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered

                className=" justify-content-center"
            >
                <Modal.Header closeButton></Modal.Header>
                <Row style={{ backgroundColor: "#fff" }}>

                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="event2000thead justify-content-center">
                                Ideation Workshops
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of this workshop was to generate ideas on which student can work in the IDEA Lab.
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                Following are the ideas-
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Idea-1 - Highway Power Generator</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Idea-2- Bluetooth Controlled Dustbin</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Idea-3- Water Level Indicator</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Idea-4- Fixed wings</Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead ">
                                Idea-1 - Highway Power Generator
                            </Row>
                            {/* <Row className="homefinmgmttext mt-3">
                                
                            </Row> */}

                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={idea1_1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={idea1_2} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead ">
                                Idea-2- Bluetooth Controlled Dustbin
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={idea2_1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={idea2_2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={idea2_3} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead ">
                                Idea-3- Water Level Indicator
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={idea3_1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={idea3_2} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead ">
                                Idea-4- Fixed wings
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={idea4_1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={idea4_2} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Row>
            </Modal>

            <Modal
                size="xl"
                show={bootcamp}
                onHide={() => setBootcamp(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered

                className=" justify-content-center"
            >
                <Modal.Header closeButton></Modal.Header>
                <Row style={{ backgroundColor: "#fff", paddingBottom:"20px" }} className="mb-5">
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="event2000thead justify-content-center">
                                Bootcamp
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                Faculty and Students needs to be trained on equipment and other technology, spread over few weeks, continuing at workplaces, if required.
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                Following are some of the area in which Bootcamp are done-
                            </Row>

                            <Row className="homefinmgmttext mt-3">
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Bootcamp on Mechanical and Electronic Tools</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >
                                        Bootcamp on Drone making
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead ">
                                Bootcamp on Mechanical and Electronic Tools
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of this hands-on skilling program is to provide the participants with the necessary knowledge of various electronics and mechanical tools. The participants will be able to understand the working of various Power and hand tools, understand the function of electronic equipment and tools, familiarize with the concept of 3D Printing and Design with more focus on Practice sessions on CAD tools and Assembly of 3D printer and understand the types of Welding.
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                Students of Govt ITI, Mana, Raipur participated in this 12 days Bootcamp
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={MET1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={MET2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={MET3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={MET4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={MET5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={MET6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={MET7} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead ">
                                Bootcamp on Drone making
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The basic objective of this Bootcamp is to train, personnel having skill set of drone pilot who can assemble drones on their own. Also on completion of the course, learner will be able to Construct and assemble a drone, familiar with ground control station (GCR), Configure and troubleshoot a drone and Piloting a Drone.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={droneboot1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={droneboot2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={droneboot3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={droneboot4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={droneboot6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={droneboot7} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={droneboot5} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Row>
            </Modal>

            <Modal
                size="xl"
                show={proskill}
                onHide={() => setProskill(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered

                className=" justify-content-center"
            >
                <Modal.Header closeButton></Modal.Header>
                <Row style={{ backgroundColor: "#fff" }}>

                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="event2000thead justify-content-center">
                                Professional Skilling Program
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The main objective of this Professional Skilling Program is to provide adequate training in market-relevant skills like Welding, 3D Printing, Mechanical and Electronic fabrication.
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead ">
                                Professional Skilling Program on Various Mechanical and Electronic tools
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of this hands-on skilling program is to provide the participants with the necessary knowledge of various electronics and mechanical tools. The participants will be able to understand the working of various Power and hand tools, understand the function of electronic equipment and tools, familiarize with the concept of 3D Printing and Design with more focus on Practice sessions on CAD tools and Assembly of 3D printer and understand the types of Welding.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={proskill1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={proskill2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={proskill3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={proskill4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={proskill5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={proskill6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={proskill7} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={proskill8} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={proskill9} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={proskill10} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Row>
            </Modal>

            <Modal
                size="xl"
                show={open}
                onHide={() => setOpen(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered

                className=" justify-content-center"
            >
                <Modal.Header closeButton></Modal.Header>
                <Row style={{ backgroundColor: "#fff" }}>

                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="event2000thead justify-content-center">
                                Open Day for Schools
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The main objective of this activity is to give School Students exposure to various facilities in IDEA lab to ignite their minds.
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                Following Schools Visited IDEA Lab in the past one year
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                <ol>
                                    <li>Govt Higher Secondary School, Bagodar</li>
                                    <li>Krishna Public School, Dunda</li>
                                    <li>Brighton International School</li>
                                    <li>Shree Gujrati Higher Secondary School</li>
                                    <li>Disha School</li>
                                    <li>NH Goel School</li>
                                    <li>Scholars English Medium School</li>
                                    <li>Bharat Mata Higher Secondary School</li>
                                    <li>SAGES, Abhanpur</li>
                                    <li>Aadarsh Vidyalaya, Mowa</li>
                                    <li>Radiant Way School</li>
                                    <li>Govt Higher Secondary School, Fundhar</li>
                                </ol>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            {/* <Row className="homfinmgmtsubhead ">
                        Faculty Development Program on Pathshaala to Prayogshaala
                    </Row> */}

                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={openday1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday7} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday8} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday9} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday10} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday11} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday12} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday13} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday18} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday14} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday15} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday16} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={openday17} alt="" className='event2000img' />
                                </Col>
                                {/* <Col sm={6} className='mt-5'>
                            <img src={openday19} alt="" className='event2000img' />
                        </Col> */}
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Row>
            </Modal>

            <Row>
                <Navigation />
            </Row>

            <Row className=" eventtopbg justify_content_center">
                <Col className="">
                    <Row className="justify_content_center aboutheadingtxt">
                        Event in 2022
                    </Row>

                    <Row className="justify_content_center ">
                        <Col className="justify_content_center ">
                            <span className="aboutustoptext1 mr-0"><Link to="/" className="aboutustoptext1">Home</Link></span>
                            <span className="aboutustoptext1 mx-3 mr-3">{">"}</span>
                            <span className="aboutustoptext2 ml-0">Event in 2022</span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* <Row className=" justify_content_center mt-5">
                <Col sm={1}></Col>
                <Col sm className='justify_content_center' >
                    <Row className="">
                        <img src={aboutimg} alt="" className="finmgmtimg1" />
                    </Row>
                </Col>

                <Col sm={1}></Col>
            </Row> */}

            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    {/* <Row className="homfinmgmthead justify_content_center">
                        Events in 2022
                    </Row> */}
                    {/* <Row className="homefinmgmttext justify-content-center mt-3">
                        Under IDEA Lab to empower students and faculty to take up and promote multidisciplinary education and innovations we have done the folloing events
                    </Row> */}
                </Col>
                <Col sm={1}></Col>
            </Row>

            <Row className="homfinmgmtsubhead" >
                <Col xs={1}></Col>
                <Col>Please Click on the cards to view details</Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="homecounterbg justify-content-center mt-5">
                <Col sm={1}></Col>
                <Col sm className="event2000card justify-content-center " data-aos="fade-down-right"
                    onClick={() => {
                        skillingrogram.current.scrollIntoView()
                        setSkillingprog(true)
                        setBootcamp(false)
                        setFaculty(false)
                        setIdeation(false)
                        setProskill(false)
                        setOpen(false)
                    }}
                >
                    <Col >
                        <Row className="justify-content-center">
                            <img src={skillingprogramimg} alt="" className="featuresimg" />
                        </Row>

                        <Row className="event2000cardtext justify-content-center mt-4  mb-4">
                            Skilling Program
                        </Row>
                    </Col>
                </Col>

                <Col sm className="event2000card justify-content-center  " data-aos="fade-down"
                    onClick={() => {
                        fdp.current.scrollIntoView()
                        setSkillingprog(false)
                        setBootcamp(false)
                        setFaculty(true)
                        setIdeation(false)
                        setProskill(false)
                        setOpen(false)
                    }}
                >
                    <Col >
                        <Row className="justify-content-center">
                            <img src={fdpimg} alt="" className="featuresimg" />
                        </Row>

                        <Row className="event2000cardtext justify-content-center mt-4  mb-4">
                            Faculty Development Program
                        </Row>
                    </Col>
                </Col>
                <Col sm className="event2000card justify-content-center " data-aos="fade-down-left">
                    <Col onClick={() => {
                        boot.current.scrollIntoView()
                        setSkillingprog(false)
                        setBootcamp(true)
                        setFaculty(false)
                        setIdeation(false)
                        setProskill(false)
                        setOpen(false)
                    }}>
                        <Row className="justify-content-center">
                            <img src={bootcampimg} alt="" className="featuresimg " />
                        </Row>

                        <Row className="event2000cardtext justify-content-center mt-4  mb-4">
                            Bootcamps
                        </Row>
                    </Col>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="homecounterbg justify-content-center mt-5">
                <Col sm={1}></Col>
                <Col sm className="event2000card justify-content-center " data-aos="fade-up-right">
                    <Col onClick={() => {
                        setSkillingprog(false)
                        setBootcamp(false)
                        setFaculty(false)
                        setIdeation(true)
                        setProskill(false)
                        setOpen(false)
                    }}>
                        <Row className="justify-content-center">
                            <img src={Workshopimg} alt="" className="featuresimg" />
                        </Row>

                        <Row className="event2000cardtext justify-content-center mt-4  mb-4">
                            Ideation Workshop
                        </Row>
                    </Col>
                </Col>

                <Col sm className="event2000card justify-content-center " data-aos="fade-up">
                    <Col onClick={() => {
                        professional.current.scrollIntoView()
                        setSkillingprog(false)
                        setBootcamp(false)
                        setFaculty(false)
                        setIdeation(false)
                        setProskill(true)
                        setOpen(false)
                    }
                    }>
                        <Row className="justify-content-center">
                            <img src={professionalskiilingimg} alt="" className="featuresimg" />
                        </Row>

                        <Row className="event2000cardtext justify-content-center mt-4  mb-4">
                            Professional Skilling Program
                        </Row>
                    </Col>
                </Col>

                <Col sm className="event2000card justify-content-center " data-aos="fade-up-left">
                    <Col onClick={() => {
                        openday.current.scrollIntoView()
                        setSkillingprog(false)
                        setBootcamp(false)
                        setFaculty(false)
                        setIdeation(false)
                        setProskill(false)
                        setOpen(true)
                    }}>
                        <Row className="justify-content-center">
                            <img src={openschoolimg} alt="" className="featuresimg" />
                        </Row>

                        <Row className="event2000cardtext justify-content-center mt-4  mb-4">
                            Open Day for Schools
                        </Row>
                    </Col>
                </Col>
                <Col sm={1}></Col>
            </Row>

            <Row ref={skillingrogram}></Row>

            {/* {skillingprog ?
                <>
                    <Row className="mt-5" >
                        <Col></Col>
                        <Col xs={6} className="homesecondsecborder"></Col>
                        <Col></Col>
                    </Row>

                    <Row className="mt-5" >
                        <Col xs={1}></Col>
                        <Col >
                            <Row className="event2000thead justify-content-center">
                                Skilling Program
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The Skilling Program, organized by The IDEA Lab at SSIPMT is an endeavor to equip the students with the knowledge of latest technologies. IDEA Lab would serve as an infrastructure for students to take up and promote multidisciplinary education and research. Accordingly, students would be encouraged to get trained in these Labs and strive for creating problems/ projects/ internships in their own subjects/ disciplines.
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                Following are some of the area in which skilling programs are done for students of different disciplines of various colleges-
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col className="eventlisttext justify_content_left" >Drone Design and Development</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Internet of Things (IoT)</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Arduino</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >Raspberry Pi</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >App Development</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >3D Printing</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >PCB Designing</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >C and Basic Electronics</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="eventlisttext justify_content_left" >IC Design Front End Approach</Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs className=''>
                            <Row className="homfinmgmtsubhead " >
                                Drone Design and Development
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The basic objective of this skilling program is to train, personnel having skill set of drone pilots who can assemble drones on their own.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={drone1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone7} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone8} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone9} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone10} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={drone11} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                Internet of Things (IoT)
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of the Training Program is to familiarize the students with the concept of Internet of Things and Application Development with more focus on Practice sessions on ARDUINO IDE and ANDROID STUDIO.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={iot1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={iot2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={iot3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={iot4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={iot5} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                Arduino
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of this skilling program is to familiarize students with Arduino as IDE, programming language & platform and to provide knowledge of Arduino boards and basic components and sensors.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={Arduino7} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                Raspberry Pi Programming with Python
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of the Skilling Program is to familiarize the faculties/students with basic Python Programming Language and the concept related to Internet of Things. Faculties/Students will get hands-on experience in working with Raspberry Pi and exploring IoT.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={RaspberryPi1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={RaspberryPi2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={RaspberryPi3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={RaspberryPi4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={RaspberryPi5} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                App Development using React Native
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                In this React Native training class, attendees learn how to use React Native to create cross-platform native apps quickly and easily. Students start from scratch and build up to a comprehensive app that uses modern techniques. In addition, participants learn best practices to consume RESTful data from a NodeJS/Express server and present it to the user in a multi-screen, interactive app.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={appdev7} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                3D Printing
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of the Skilling Program is to familiarize the students with the concept of 3D Printing and Design with more focus on Practice session on CAD Software and Assembly of 3D printer, Troubleshooting of 3D Printers.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={img3dprint6} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                PCB Designing
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of the workshop is to familiarize the students with the concept of Electronic Components and PCB Designing with more focus on Practice session on Eagle Software and Designing of PCB.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb4} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb5} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb6} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb7} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={pcb8} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                C and Basic Electronics
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The major objective is to provide students with understanding of code organization and functional hierarchical decomposition with using complex data types. This course provides the student with the fundamental skills to understand the basic of semiconductor and components like diode, transistors and various types of Sensors.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={cbe1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={cbe2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={cbe3} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={cbe4} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="eventmobmargin mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="homfinmgmtsubhead " id="Faculty_Development_Program">
                                IC Design Front End Approach
                            </Row>
                            <Row className="homefinmgmttext mt-3">
                                The objective of this skilling program is to make students understand the basics of IC design using HDL and also Simulation of circuits using Xilinx.
                            </Row>
                            <Row >
                                <Col sm={6} className='mt-5'>
                                    <img src={ic1} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={ic2} alt="" className='event2000img' />
                                </Col>
                                <Col sm={6} className='mt-5'>
                                    <img src={ic3} alt="" className='event2000img' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </>
                :
                null
            } */}

            <Row ref={fdp}></Row>

            {/* {
                faculty ?
                    <>
                        <Row className="mt-5">
                            <Col></Col>
                            <Col xs={6} className="homesecondsecborder"></Col>
                            <Col></Col>
                        </Row>

                        <Row className="eventmobmargin mt-5">
                            <Col xs={1}></Col>
                            <Col xs>
                                <Row className="event2000thead justify-content-center">
                                    Faculty Development Program
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    Faculty needs to be trained on equipment, to be able to guide students and conduct events- including those from other institutions.
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    Following are some of the area in which faculty development programs are done-
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    <Row className="mt-3">
                                        <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                        <Col xs className="eventlisttext justify_content_left" >Faculty Development Program on Pathshaala to Prayogshaala</Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                        <Col xs className="eventlisttext justify_content_left" >Faculty Development program on 3D Printing</Col>
                                    </Row>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                        <Row className="eventmobmargin mt-5">
                            <Col xs={1}></Col>
                            <Col xs>
                                <Row className="homfinmgmtsubhead ">
                                    Faculty Development Program on Pathshaala to Prayogshaala
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    The main objective of this FDP is to put "Theory into Practice" with the intent to impart practical knowledge to help young learners develop something new of their one with the help of their teachers. This will enhance the spirit of Ideation and innovation in the students.
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    This program was conducted for School teachers. 31 Schools Teachers from various schools participated in this program. They all were trained on the Science experimental kits developed by the SSIPMT IDEA Lab.
                                </Row>
                                <Row >
                                    <Col sm={6} className='mt-5'>
                                        <img src={p2p1} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={p2p2} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={p2p3} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={p2p4} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={p2p5} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={p2p6} alt="" className='event2000img' />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                        <Row className="eventmobmargin mt-5">
                            <Col xs={1}></Col>
                            <Col xs>
                                <Row className="homfinmgmtsubhead ">
                                    Faculty Development program on 3D Printing
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    The objective of this Faculty Development Program is to familiarize the faculty with the concept of 3D Printing and Design with more focus on Practice session on CAD Software and Assembly of 3D printer, Troubleshooting of 3D Printers.
                                </Row>
                                <Row >
                                    <Col sm={6} className='mt-5'>
                                        <img src={fdp3dprint1} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={fdp3dprint2} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={fdp3dprint3} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={fdp3dprint4} alt="" className='event2000img' />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                    </>
                    :
                    null
            } */}

            <Row ref={boot}></Row>

            {/* {
                bootcamp ?
                    <>

                        <Row className="mt-5">
                            <Col></Col>
                            <Col xs={6} className="homesecondsecborder"></Col>
                            <Col></Col>
                        </Row>

                        <Row className="eventmobmargin mt-5">
                            <Col xs={1}></Col>
                            <Col xs>
                                <Row className="event2000thead justify-content-center">
                                    Bootcamp
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    Faculty and Students needs to be trained on equipment and other technology, spread over few weeks, continuing at workplaces, if required.
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    Following are some of the area in which Bootcamp are done-
                                </Row>

                                <Row className="homefinmgmttext mt-3">
                                    <Row className="mt-3">
                                        <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                        <Col xs className="eventlisttext justify_content_left" >Bootcamp on Mechanical and Electronic Tools</Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                        <Col xs className="eventlisttext justify_content_left" >
                                            Bootcamp on Drone making
                                        </Col>
                                    </Row>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                        <Row className="mt-5">
                            <Col xs={1}></Col>
                            <Col xs>
                                <Row className="homfinmgmtsubhead ">
                                    Bootcamp on Mechanical and Electronic Tools
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    The objective of this hands-on skilling program is to provide the participants with the necessary knowledge of various electronics and mechanical tools. The participants will be able to understand the working of various Power and hand tools, understand the function of electronic equipment and tools, familiarize with the concept of 3D Printing and Design with more focus on Practice sessions on CAD tools and Assembly of 3D printer and understand the types of Welding.
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    Students of Govt ITI, Mana, Raipur participated in this 12 days Bootcamp
                                </Row>
                                <Row >
                                    <Col sm={6} className='mt-5'>
                                        <img src={MET1} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={MET2} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={MET3} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={MET4} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={MET5} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={MET6} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={MET7} alt="" className='event2000img' />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                        <Row className="mt-5">
                            <Col xs={1}></Col>
                            <Col xs>
                                <Row className="homfinmgmtsubhead ">
                                    Bootcamp on Drone making
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    The basic objective of this Bootcamp is to train, personnel having skill set of drone pilot who can assemble drones on their own. Also on completion of the course, learner will be able to Construct and assemble a drone, familiar with ground control station (GCR), Configure and troubleshoot a drone and Piloting a Drone.
                                </Row>
                                <Row >
                                    <Col sm={6} className='mt-5'>
                                        <img src={droneboot1} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={droneboot2} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={droneboot3} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={droneboot4} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={droneboot6} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={droneboot7} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={droneboot5} alt="" className='event2000img' />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                    </>
                    :
                    null
            } */}

            <Row ref={professional}></Row>

            {/* {
                proskill ?
                    <>
                        <Row className="mt-5">
                            <Col></Col>
                            <Col xs={6} className="homesecondsecborder"></Col>
                            <Col></Col>
                        </Row>

                        <Row className="eventmobmargin mt-5">
                            <Col xs={1}></Col>
                            <Col xs>
                                <Row className="event2000thead justify-content-center">
                                    Professional Skilling Program
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    The main objective of this Professional Skilling Program is to provide adequate training in market-relevant skills like Welding, 3D Printing, Mechanical and Electronic fabrication.
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                        <Row className="mt-5">
                            <Col xs={1}></Col>
                            <Col xs>
                                <Row className="homfinmgmtsubhead ">
                                    Professional Skilling Program on Various Mechanical and Electronic tools
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    The objective of this hands-on skilling program is to provide the participants with the necessary knowledge of various electronics and mechanical tools. The participants will be able to understand the working of various Power and hand tools, understand the function of electronic equipment and tools, familiarize with the concept of 3D Printing and Design with more focus on Practice sessions on CAD tools and Assembly of 3D printer and understand the types of Welding.
                                </Row>
                                <Row >
                                    <Col sm={6} className='mt-5'>
                                        <img src={proskill1} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={proskill2} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={proskill3} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={proskill4} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={proskill5} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={proskill6} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={proskill7} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={proskill8} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={proskill9} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={proskill10} alt="" className='event2000img' />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                    </>
                    :
                    null
            } */}

            <Row ref={openday}></Row>

            {/* {
                open ?
                    <>
                        <Row className="mt-5">
                            <Col></Col>
                            <Col xs={6} className="homesecondsecborder"></Col>
                            <Col></Col>
                        </Row>

                        <Row className="eventmobmargin mt-5">
                            <Col xs={1}></Col>
                            <Col xs>
                                <Row className="event2000thead justify-content-center">
                                    Open Day for Schools
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    The main objective of this activity is to give School Students exposure to various facilities in IDEA lab to ignite their minds.
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    Following Schools Visited IDEA Lab in the past one year
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    <ol>
                                        <li>Govt Higher Secondary School, Bagodar</li>
                                        <li>Krishna Public School, Dunda</li>
                                        <li>Brighton International School</li>
                                        <li>Shree Gujrati Higher Secondary School</li>
                                        <li>Disha School</li>
                                        <li>NH Goel School</li>
                                        <li>Scholars English Medium School</li>
                                        <li>Bharat Mata Higher Secondary School</li>
                                        <li>SAGES, Abhanpur</li>
                                        <li>Aadarsh Vidyalaya, Mowa</li>
                                        <li>Radiant Way School</li>
                                        <li>Govt Higher Secondary School, Fundhar</li>
                                    </ol>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                        <Row className="mt-5">
                            <Col xs={1}></Col>
                            <Col xs>
                                <Row >
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday1} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday2} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday3} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday4} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday5} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday6} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday7} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday8} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday9} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday10} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday11} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday12} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday13} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday18} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday14} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday15} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday16} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={openday17} alt="" className='event2000img' />
                                    </Col>
                                    
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                    </>
                    :
                    null
            } */}

            <Row className="footermargin  mt-5">
                <Footer />
            </Row>

        </div >
    )
}

export default Events2022