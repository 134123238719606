import React, { useEffect, useState } from "react";
import "./Styles.css"
import Footer from "./Footer";
import Aos from "aos"
import "aos/dist/aos.css"
import Navigation from "./Navigation";
import { Row, Col, Carousel, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCheck, FaQuoteLeft } from "react-icons/fa"
import Carouselimg1 from "./Images/Homecorousel1.jpeg"
import Carouselimg2 from "./Images/Homecorousel2.jpg"
import Carouselimg3 from "./Images/Homecorousel3.jpg"
import idealablogo from "./Images/idea_lab.png"
import bootcampimg from "./Images/Bootcamp.png"
import fdpimg from "./Images/FDP.png"
import openschoolimg from "./Images/Open school.png"
import skillingprogramimg from "./Images/Skilling program.png"
import Workshopimg from "./Images/Workshop.jpg"
import cnc_cutter from "./Images/Facilities/CNC Laser Cutter1.png"
import cnc_wood from "./Images/Facilities/CNC_Wood_Router.png"
import Soldering from "./Images/Facilities/Soldering.png"
import icon3dprinter from "./Images/Facilities/3D_Printer.png"
import Sublimation from "./Images/Facilities/Sublimation_Printer.png"
import Mechanical_tools from "./Images/Facilities/Mechanical_tools.png"
import d1 from "./Images/Drone_Pics_&_Videos/D1.jpg"
import d2 from "./Images/Drone_Pics_&_Videos/D2.jpg"
import d3 from "./Images/Drone_Pics_&_Videos/D3.jpg"
import d4 from "./Images/Drone_Pics_&_Videos/D4.jpeg"
import d5 from "./Images/Drone_Pics_&_Videos/D5.jpeg"
import d6 from "./Images/Drone_Pics_&_Videos/D6.jpeg"
import d7 from "./Images/Drone_Pics_&_Videos/D7.jpeg"
import d8 from "./Images/Drone_Pics_&_Videos/D8.jpeg"
import d3_without_bg from "./Images/Drone_Pics_&_Videos/D3-removebg-preview.png"
import Url from './Url';

const Homepage = () => {

    const url = Url()
    const s3 = url["s3"]

    const [showdronecarousel, setShowdronecarousel] = useState(false)

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    // const callBack = () => {
    //     // var elmnt = document.getElementById("Callback");
    //     // elmnt.scrollIntoView();
    //     // window.scrollTo({ behavior: 'smooth' });
    //     window.scrollTo(0, 800)
    // }

    return (
        <>

            {/* <div>
                <Widget />
            </div> */}
            <Modal
                size="lg"
                show={showdronecarousel}
                onHide={() => setShowdronecarousel(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Row>
                    <Carousel fade className="">
                        <Carousel.Item interval='2000' >
                            <img
                                className="d-block w-100"
                                src={d1}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval='1000' >
                            <img
                                className="d-block w-100"
                                src={d2}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval='1000' >
                            <img
                                className="d-block w-100"
                                src={d3}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval='1000' >
                            <img
                                className="d-block w-100"
                                src={d4}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval='1000' >
                            <img
                                className="d-block w-100"
                                src={d5}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval='1000' >
                            <img
                                className="d-block w-100"
                                src={d6}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval='1000' >
                            <img
                                className="d-block w-100"
                                src={d7}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval='1000' >
                            <img
                                className="d-block w-100"
                                src={d8}
                                alt="First slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </Row>
            </Modal>

            <Row className="mb-5">

                <Navigation />
            </Row>
            <Row className="mt-5">
                <Carousel fade className="">
                    <Carousel.Item interval='3000'>
                        <img
                            className="d-block w-100"
                            src={Carouselimg1}
                            alt="First slide"
                        />
                        <Carousel.Caption>

                            <Col xs></Col>
                            <Col xs className="mt-0">
                               
                            </Col>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval='3000'>
                        <img
                            className="d-block w-100"
                            src={Carouselimg2}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval='3000' >
                        <img
                            className="d-block w-100"
                            src={Carouselimg3}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    {/* <Carousel.Item interval='1000' >
                        <img
                            className="d-block w-100"
                            src={Carouselimg4}
                            alt="First slide"
                        />
                    </Carousel.Item> */}
                </Carousel>
            </Row>

            <Row >
                <Col xs={1}></Col>
                <Col className="justify_content_center">
                    <Row className="solutionimgbgnew123">
                    </Row>
                    <Row className="">
                        <img src={idealablogo} alt="" className="idealablogohomepage" />
                    </Row>
                </Col>
                <Col sm={6} className="mt-0" data-aos="fade-right">
                    <Row className="homesecondsecwelcom mt-5 mx-2">
                        AICTE IDEA lab
                    </Row>
                    <Row className="homesecondsechead mt-4 mx-2">
                        SSIPMT IDEA lab
                    </Row>
                    <Row className="mt-4">
                        <Col xs={5} className="homesecondsecborder"></Col>
                    </Row>
                    <Row className="homesecondsecdesc mt-4 mx-3">
                        To Transform Technical Education by Empowering Young Minds with Current Technologies, Processes, and Skills for Atmanirbhar Bharat.
                    </Row>
                    <Row className="mt-5">
                        <Col xs>
                            <Row className="homfinmgmtsubhead mx-2">
                                <Col>IDEA Lab Mission</Col>
                            </Row>
                            <Row className="homefinmgmttext mt-3 mx-2">
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="homefinmgmttext justify_content_left" >To provide facilities under one roof, for conversion of an idea into a prototype.</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="homefinmgmttext justify_content_left" >To empower students and faculty to take up and promote multidisciplinary education and innovations with aspects of “engage, explore, experience, express and excel”.</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                    <Col xs className="homefinmgmttext justify_content_left" >To encourage students to apply science, technology, engineering, and mathematics (STEM) fundamentals to seek solutions to real-life problems.</Col>
                                </Row>
                            </Row>
                        </Col>
                    </Row >
                </Col>
                <Col xs={1}></Col>
            </Row>

            {/* <Row className="mt-5">
                <Col xs={1}></Col>
                <Col className="">
                    <Row className="homesecondsecdesc ">
                        Our IDEA Lab was inaugurated on 8th February 2022. We were lucky to have Dr Neeraj Saxena Advisor-IDC, AICTE with us on this auspicious day.
                    </Row>
                    <Row className="homesecondsecdesc mt-4">
                        Along with Neeraj Sir we had Dr Premsai Singh Tekam, Cabinet Minister, School Education, Govt of Chhattisgarh as our Chief Guest and Shri I P Mishra, President, Shri Gangajali Education Society as our Special Guest.
                    </Row>
                    <Row>
                    <Col sm={6} className='mt-5'>
                            <img src={Inaugurationpic1} alt="" className='event2000img' />
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <img src={Inaugurationpic2} alt="" className='event2000img' />
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row> */}

            <Row className="homepagesecndsechead justify_content_center mt-5">
                {/*Our Projects*/} What We Do
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={4} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            {/* <Row className=" justify_content_center mt-2">
                <img src={dividerimg} alt="" className="dividerimg" />
            </Row> */}

            <Row className=" justify_content_center mt-2">
                <Col xs={3}></Col>
                <Col >
                    <Row className="homepagesecndsectext justify_content_center mt-2">
                        {/* Wealth Management and Financial Consulting - */}
                    </Row>
                    <Row className="homepagesecndsectext1 mt-4">
                        Under IDEA Lab we empower students and faculty to take up and promote multidisciplinary education and innovations with aspects of “engage, explore, experience, express and excel”.
                        {/*We cover you for almost everything in your Financial Journey. Our holistic approach in consulting and a range of investment options empower you to take the right decision in your financial matters - be it financing, investing, accounting or wealth management.*/}
                    </Row>
                </Col>
                <Col xs={3}></Col>
            </Row>

            <Row className="hompagdronebg mt-5">
                <Col xs={1}></Col>
                <Col sm={4}>
                    <img src={d3_without_bg} alt="" className="hompagdroneimgs"
                        onClick={() => setShowdronecarousel(true)}
                    />
                    {/* <video width="320px" height="auto" autoplay="autoplay" loop="" controls="true" controlsList="nodownload" 
                className="demo_video"
                >
                    <source src={demo_video} type="video/mp4" />
                </video> */}
                </Col>
                <Col sm className="" style={{ display: "grid", alignContent: "center" }}>
                    <Row className="droneusphead justify_content_center">
                        CORE COMPETENCY – AERIAL ROBOTICS
                    </Row>
                    {/* <Row className="mt-4">
                        <Col></Col>
                        <Col xs={4} className="homesecondsecborder"></Col>
                        <Col></Col>
                    </Row> */}
                    <Row className="droneuspdesc1 justify_content_center mt-3">
                        We deal in selling and training of all types of Drones and Aeromodeling.
                    </Row>
                    <Link to="/Drone">
                        <Row className="homservicesbtncrd justify_content_center mt-4">
                            <button className="getstartedbtn">Know More </button>
                        </Row>
                    </Link>
                    {/* <Row>
                        <Row className="mt-5">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="droneuspdesc justify_content_left" >Photography/Cinematography Drones</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="droneuspdesc justify_content_left" >Payload/Delivery Drones</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="droneuspdesc justify_content_left" >Agricultural drones</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="droneuspdesc justify_content_left" >Sports/Racing Drones</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="droneuspdesc justify_content_left" >FPV Drones</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="droneuspdesc justify_content_left" >Autonomous Drone</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="droneuspdesc justify_content_left" >Spy Drone</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="droneuspdesc justify_content_left" >Micro-drone</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="droneuspdesc justify_content_left" >Fixed Wings – Glider RC Plane, Top wing RC Plane and Cargo RC Plane</Col>
                        </Row>
                    </Row> */}
                </Col>
                <Col xs={1}></Col>
            </Row>

            {/* <Row className="mt-5">
                <Col className="justify_content_center "><img src={puzzzleimg} alt="" className="imgrotate" /></Col>

            </Row> */}

            <Row className="justify_content_center mt-5">
                <Col sm={2}></Col>
                <Col sm data-aos="fade-down-right" className="homservicescard" >
                    <span >
                        <img src={skillingprogramimg} alt="" className="homwhatwedoicons" />
                    </span>
                    <span className="">
                        <Row className="homfeatureheading">
                            Skilling Program
                        </Row>
                        <Row className="homfeaturetext mt-4">
                            The Skilling Program, organized by The IDEA Lab at SSIPMT is an endeavor to equip the students with the knowledge of latest technologies.
                        </Row>
                        {/* <Link to="/Services">
                            <Row className="homsrvlrnmore mt-4">
                            Learn More ->
                            </Row>
                        </Link> */}
                    </span>
                </Col>
                <Col sm data-aos="fade-down" className="homservicescard ">
                    <span >
                        <img src={bootcampimg} alt="" className="homwhatwedoicons" />
                    </span>
                    <span className="">
                        <Row className="homfeatureheading">
                            Bootcamps
                        </Row>
                        <Row className="homfeaturetext mt-4">
                        Faculty and Students needs to be trained on equipment and other technology, spread over few weeks, continuing at workplaces, if required.
                        </Row>
                        {/* <Link to="/Services">
                            <Row className="homsrvlrnmore mt-4">
                            Learn More ->
                            </Row>
                        </Link> */}
                    </span>
                </Col>
                <Col sm data-aos="fade-down-left" className="homservicescard">
                    <span >
                        <img src={fdpimg} alt="" className="homwhatwedoicons" />
                    </span>
                    <span className="" style={{marginLeft:"-20px"}}>
                        <Row className="homfeatureheading">
                            Faculty Development Program
                        </Row>
                        <Row className="homfeaturetext mt-4">
                            Faculty needs to be trained on equipment, to be able to guide students and conduct events- including those from other institutions.
                        </Row>
                        {/* <Link to="/Services">
                            <Row className="homsrvlrnmore mt-4">
                            Learn More ->
                            </Row>
                        </Link> */}
                    </span>
                </Col>
                <Col sm={2}></Col>
            </Row>

            <Row className="justify_content_center mt-5 mb-5">
                <Col sm={2}></Col>
                <Col sm data-aos="fade-up-right" className="homservicescard">
                    <span >
                        <img src={Workshopimg} alt="" className="homwhatwedoicons" />
                    </span>
                    <span className="">
                        <Row className="homfeatureheading">
                            Ideation Workshop
                        </Row>
                        <Row className="homfeaturetext mt-4">
                        To generate ideas on which student can work in the IDEA Lab.
                        </Row>
                        {/* <Link to="/Services">
                            <Row className="homsrvlrnmore mt-4">
                            Learn More ->
                            </Row>
                        </Link> */}
                    </span>
                </Col>
                <Col sm data-aos="fade-up" className="homservicescard">
                    <span >
                        <img src={openschoolimg} alt="" className="homwhatwedoicons" />
                    </span>
                    <span className="">
                        <Row className="homfeatureheading">
                            Open Day for Schools
                        </Row>
                        <Row className="homfeaturetext mt-4">
                        The main objective of this activity is to give School Students exposure to various facilities in IDEA lab to ignite their minds.
                        </Row>
                        {/* <Link to="/Services">
                            <Row className="homsrvlrnmore mt-4">
                            Learn More ->
                            </Row>
                        </Link> */}
                    </span>
                </Col>
                <Col sm data-aos="fade-up-left" className="homservicesbtncrd">
                    <Link to="/Events">
                        <button className="getstartedbtn">Know More </button>
                    </Link>
                </Col>
                <Col sm={2}></Col>
            </Row>

            <Row className="bloglisthead1 justify-content-center mt-5 ">
                <b>Our Facilities</b>
            </Row>

            <Row className="homecounterbg justify-content-center mt-5">

                <Col sm className="homecountercard justify-content-center " data-aos="fade-right">
                    <Link to="/Facilities">
                        <Col >
                            <Row className="justify-content-center">
                                <img src={icon3dprinter} alt="" className="featuresimg" />
                            </Row>
                            {/* <Row className="homecountercardicon mt-4">
                            <AiOutlineUser />
                        </Row>
                        <Row className="homecountercardno justify-content-center mt-2">
                            25136
                        </Row> */}
                            <Row className="homecountercardtext justify-content-center mt-2  mb-4">
                                3D Printing
                            </Row>
                        </Col>
                    </Link>
                </Col>

                <Col sm className="homecountercard justify-content-center " data-aos="fade-right">
                    <Link to="/Facilities">
                        <Col >
                            <Row className="justify-content-center">
                                <img src={Sublimation} alt="" className="featuresimg" />
                            </Row>
                            {/* <Row className="homecountercardicon mt-4">
                            <IoTrophyOutline />
                        </Row>
                        <Row className="homecountercardno justify-content-center mt-2">
                            1235789
                        </Row> */}
                            <Row className="homecountercardtext justify-content-center mt-2  mb-4">
                                Sublimation Printing
                            </Row>
                        </Col>
                    </Link>
                </Col>

                <Col sm className="homecountercard justify-content-center  " data-aos="fade-up">
                    <Link to="/Facilities">
                        <Col >
                            <Row className="justify-content-center">
                                <img src={cnc_cutter} alt="" className="featuresimg" />
                            </Row>
                            {/* <Row className="homecountercardicon mt-4">
                            <IoExtensionPuzzleOutline />
                        </Row>
                        <Row className="homecountercardno justify-content-center mt-2">
                            635
                        </Row> */}
                            <Row className="homecountercardtext justify-content-center mt-2  mb-4">
                                CNC Laser Cutter
                            </Row>
                        </Col>
                    </Link>
                </Col>

                <Col sm className="homecountercard justify-content-center " data-aos="fade-up">
                    <Link to="/Facilities">
                        <Col >
                            <Row className="justify-content-center">
                                <img src={cnc_wood} alt="" className="featuresimg " />
                            </Row>
                            {/* <Row className="homecountercardicon mt-4">
                            <IoTrophyOutline />
                        </Row>
                        <Row className="homecountercardno justify-content-center mt-2">
                            1235789
                        </Row> */}
                            <Row className="homecountercardtext justify-content-center mt-2  mb-4">
                                CNC Wood Router
                            </Row>
                        </Col>
                    </Link>
                </Col>

                <Col sm className="homecountercard justify-content-center " data-aos="fade-left">
                    <Link to="/Facilities">
                        <Col >
                            <Row className="justify-content-center">
                                <img src={Soldering} alt="" className="featuresimg" />
                            </Row>
                            {/* <Row className="homecountercardicon mt-4">
                            <IoTrophyOutline />
                        </Row>
                        <Row className="homecountercardno justify-content-center mt-2">
                            1235789
                        </Row> */}
                            <Row className="homecountercardtext justify-content-center mt-2  mb-4">
                                PCB Designing
                            </Row>
                        </Col>
                    </Link>
                </Col>

                <Col sm className="homecountercard justify-content-center " data-aos="fade-left">
                    <Link to="/Facilities">
                        <Col >
                            <Row className="justify-content-center">
                                <img src={Mechanical_tools} alt="" className="featuresimg" />
                            </Row>
                            {/* <Row className="homecountercardicon mt-4">
                            <IoTrophyOutline />
                        </Row>
                        <Row className="homecountercardno justify-content-center mt-2">
                            1235789
                        </Row> */}
                            <Row className="homecountercardtext justify-content-center mt-2  mb-4">
                                Mechanical Handtools
                            </Row>
                        </Col>
                    </Link>
                </Col>
            </Row>

            {/* <Row >
                <Col xs={1}></Col>
                <Col sm={6} className="mt-5" data-aos="fade-right"> */}
            {/* <Row className="homesecondsecwelcom mt-5">
                        WELCOME TO SSIPMT Idea lab
                    </Row> */}
            {/* <Row className="homesecondsechead mt-4">
                        We Will Provide Best Services.
                    </Row>
                    <Row className="mt-4">
                        <Col xs={3} className="homesecondsecborder"></Col>
                    </Row>
                    <Row className="homesecondsecdesc mt-4 mx-2">

                        We are a technology savvy team, equipped with the latest technology and communication system. We have our business presence across India by way of strategic alliances and networking with fellow professional firms to ensure timely deliverables to our valuable clients.
                    </Row>
                    <Row className="">
                        <Col sm className="mt-4">
                            <div className="homesecondsecbullets" justify_content_left>
                                <FaCheck className="homesecondsectick" /> Learn from customer feedback
                            </div>
                            <div className="homesecondsecbullets mt-3" justify_content_left>
                                <FaCheck className="homesecondsectick" /> Professional Team
                            </div>
                            <div className="homesecondsecbullets mt-3" justify_content_left>
                                <FaCheck className="homesecondsectick" /> High Savings Potential
                            </div>
                            <div className="homesecondsecbullets mt-3" justify_content_left>
                                <FaCheck className="homesecondsectick" /> 24/7 customer support
                            </div>
                        </Col>
                        <Col sm className="mt-4">
                            <div className="homesecondsecbullets" justify_content_left>
                                <FaCheck className="homesecondsectick" /> High Savings Potential
                            </div>
                            <div className="homesecondsecbullets mt-3" justify_content_left>
                                <FaCheck className="homesecondsectick" /> Professional Team
                            </div>
                            <div className="homesecondsecbullets mt-3" justify_content_left>
                                <FaCheck className="homesecondsectick" /> 24/7 customer support
                            </div>
                            <div className="homesecondsecbullets mt-3" justify_content_left>
                                <FaCheck className="homesecondsectick" /> Learn from customer feedback
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col className="">
                    <Row className="solutionimgbgnew">
                    </Row>
                    <Row className="">
                        <img src={serviceimg} alt="" className="finmgmtimg" />
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row> */}

            {/* <Row className="homcoutersecbg  mt-5">
                <Col sm={1}></Col>
                <Col sm className="mt-3">
                    <Row className="justify_content_center">
                        <HiOutlineReceiptTax className="homefifthsecboxicon " />
                    </Row>
                    <Row className="homcouterseccount justify_content_center mt-3">
                        20+
                    </Row>
                    <Row className="homcoutersecdec justify_content_center mt-0">
                        Patents
                    </Row>
                </Col>
                <Col sm className="mt-3">
                    <Row className="justify_content_center">
                        <HiOutlineReceiptTax className="homefifthsecboxicon " />
                    </Row>
                    <Row className="homcouterseccount justify_content_center mt-3">
                        50+
                    </Row>
                    <Row className="homcoutersecdec justify_content_center mt-0">
                        Projects
                    </Row>
                </Col>
                <Col sm className="mt-3">
                    <Row className="justify_content_center">
                        <HiOutlineReceiptTax className="homefifthsecboxicon " />
                    </Row>
                    <Row className="homcouterseccount justify_content_center mt-3">
                        500+
                    </Row>
                    <Row className="homcoutersecdec justify_content_center mt-0">
                        Training Prprovided
                    </Row>
                </Col>
                <Col sm className="mt-3">
                    <Row className="justify_content_center">
                        <HiOutlineReceiptTax className="homefifthsecboxicon " />
                    </Row>
                    <Row className="homcouterseccount justify_content_center mt-3">
                        5+
                    </Row>
                    <Row className="homcoutersecdec justify_content_center mt-0">
                        Partners Available
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row> */}



            <Row className="mt-5">
                <Footer />
            </Row>
        </>
    )
}

export default Homepage