import React, { useEffect, useRef } from 'react'
import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navigation from "./Navigation"
import { Row, Col } from "react-bootstrap"
//import aboutimg from "./Images/SSIPMT_logo.png"
import Media1 from "./Images/Events/Media/Media1.jpeg"
import Media2 from "./Images/Events/Media/Media2.jpeg"
import Media3 from "./Images/Events/Media/Media3.jpeg"
import Media4 from "./Images/Events/Media/Media4.jpeg"
import Media5 from "./Images/Events/Media/Media5.jpeg"
import Media6 from "./Images/Events/Media/Media6.jpeg"
import Media7 from "./Images/Events/Media/Media7.jpeg"
import Media8 from "./Images/Events/Media/Media8.jpeg"
import Media9 from "./Images/Events/Media/Media9.jpeg"
import Media10 from "./Images/Events/Media/Media10.jpeg"
import Media11 from "./Images/Events/Media/Media11.jpeg"
import Media12 from "./Images/Events/Media/Media12.jpeg"
import Media13 from "./Images/Events/Media/Media13.jpeg"
import Media14 from "./Images/Events/Media/Media14.jpeg"
import Media15 from "./Images/Events/Media/Media15.jpeg"
import Media16 from "./Images/Events/Media/Media16.jpeg"
import Media17 from "./Images/Events/Media/Media17.jpeg"
import Media18 from "./Images/Events/Media/Media18.jpeg"
import Media19 from "./Images/Events/Media/Media19.jpeg"

const Media = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className=" eventtopbg justify_content_center">
                <Col className="">
                    <Row className="justify_content_center aboutheadingtxt">
                        Media Coverage
                    </Row>

                    <Row className="justify_content_center ">
                        <Col className="justify_content_center ">

                            <span className="aboutustoptext1 mr-0"><Link to="/" className="aboutustoptext1">Home</Link></span>
                            <span className="aboutustoptext1 mx-3 mr-3">{">"}</span>
                            <span className="aboutustoptext2 ml-0">Media Coverage</span>
                        </Col>
                    </Row>
                </Col>
            </Row>


            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>

                    <Row >
                            <Col sm={6} className='mediacard justify_content_center mt-5'>
                                <img src={Media1} alt="" className='mediaimg' />
                            </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media2} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media3} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media4} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media5} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media6} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media7} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media8} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media9} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media10} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media11} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media12} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media13} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media14} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media15} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media16} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media17} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media18} alt="" className='mediaimg' />
                        </Col>
                        <Col sm={6} className='mediacard justify_content_center mt-5'>
                            <img src={Media19} alt="" className='mediaimg' />
                        </Col>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>


            <Row className="footermargin  mt-5">
                <Footer />
            </Row>

        </div >
    )
}

export default Media