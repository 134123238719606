import React, { useState, useEffect } from "react"
import { Row, Col, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
// import Widget from './Widget';
import Navigation from "./Navigation"
import drone1 from "./Images/Events/Drone training/Drone1.jpg"
import drone2 from "./Images/Events/Drone training/Drone2.jpg"
import drone3 from "./Images/Events/Drone training/Drone3.jpg"
import drone4 from "./Images/Events/Drone training/Drone4.jpg"
import drone5 from "./Images/Events/Drone training/Drone5.jpg"
import drone6 from "./Images/Events/Drone training/Drone6.jpg"
import drone7 from "./Images/Events/Drone training/Drone7.jpg"
import drone8 from "./Images/Events/Drone training/Drone8.jpg"
import drone9 from "./Images/Events/Drone training/Drone9.jpeg"
import drone10 from "./Images/Events/Drone training/Drone10.jpeg"
import drone11 from "./Images/Events/Drone training/Drone11.jpeg"
import dividerimg from "./Images/divider.png"
import Footer from "./Footer"

const Gallery = () => {

    const [showcarousel, setShowcarousel] = useState(false)
    const [modalimg, setModalimg] = useState(0)

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            {/* <div>
                <Widget />
            </div> */}

            <Modal
                size="lg"
                show={showcarousel}
                onHide={() => setShowcarousel(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
          
        </Modal.Header>
                <Row>
                    <img
                        className=""
                        src={modalimg}
                        alt=""
                    />
                </Row>
            </Modal>

            <Row className="mb-5">
                <Navigation />
            </Row>
            <Row className="mt-5"></Row>

            <Row className=" gallery_topbg justify_content_center">
                <Col className="">
                    <Row className="justify_content_center aboutheadingtxt">
                        Gallery
                    </Row>

                    <Row className="justify_content_center ">
                        <Col className="justify_content_center ">
                            <span className="aboutustoptext1 mr-0"><Link to="/" className="aboutustoptext1">Home</Link></span>
                            <span className="aboutustoptext1 mx-3 mr-3">{">"}</span>
                            <span className="aboutustoptext2 ml-0">Gallery</span>
                        </Col>
                    </Row>
                </Col>
            </Row>



            <Row className="homfinmgmthead justify_content_center mt-5">
                Gallery
            </Row>
            <Row className=" justify_content_center mt-2">
                <img src={dividerimg} alt="" className="dividerimg" />
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col>
                    <Row>
                        <Col sm={2} className="mt-4">
                            <img src={drone1} alt="" className="gallery_img"
                                onClick={() => {
                                    setShowcarousel(true)
                                    setModalimg(drone1)
                                }}
                            />
                        </Col>
                        <Col sm={2} className="mt-4">
                            <img src={drone2} alt="" className="gallery_img"
                                onClick={() => {
                                    setShowcarousel(true)
                                    setModalimg(drone2)
                                }}
                            />
                        </Col>
                        <Col sm={2} className="mt-4">
                            <img src={drone3} alt="" className="gallery_img"
                                onClick={() => {
                                    setShowcarousel(true)
                                    setModalimg(drone3)
                                }}
                            />
                        </Col>
                        <Col sm={2} className="mt-4">
                            <img src={drone4} alt="" className="gallery_img"
                                onClick={() => {
                                    setShowcarousel(true)
                                    setModalimg(drone4)
                                }}
                            />
                        </Col>
                        <Col sm={2} className="mt-4">
                            <img src={drone5} alt="" className="gallery_img"
                                onClick={() => {
                                    setShowcarousel(true)
                                    setModalimg(drone5)
                                }}
                            />
                        </Col>
                        <Col sm={2} className="mt-4">
                            <img src={drone6} alt="" className="gallery_img"
                                onClick={() => {
                                    setShowcarousel(true)
                                    setModalimg(drone6)
                                }}
                            />
                        </Col>
                        <Col sm={2} className="mt-4">
                            <img src={drone7} alt="" className="gallery_img"
                                onClick={() => {
                                    setShowcarousel(true)
                                    setModalimg(drone7)
                                }}
                            />
                        </Col>
                        <Col sm={2} className="mt-4">
                            <img src={drone8} alt="" className="gallery_img"
                                onClick={() => {
                                    setShowcarousel(true)
                                    setModalimg(drone8)
                                }}
                            />
                        </Col>
                        <Col sm={2} className="mt-4">
                            <img src={drone9} alt="" className="gallery_img"
                                onClick={() => {
                                    setShowcarousel(true)
                                    setModalimg(drone9)
                                }}
                            />
                        </Col>
                        <Col sm={2} className="mt-4">
                            <img src={drone10} alt="" className="gallery_img"
                                onClick={() => {
                                    setShowcarousel(true)
                                    setModalimg(drone10)
                                }}
                            />
                        </Col>
                        <Col sm={2} className="mt-4">
                            <img src={drone11} alt="" className="gallery_img"
                                onClick={() => {
                                    setShowcarousel(true)
                                    setModalimg(drone11)
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Footer />
            </Row>
        </>
    )
}

export default Gallery