import React from "react"
import { Row, Col } from "react-bootstrap"
// import footerlogo from "./Images/footer-logo.png"
import footerlogo from "./Images/SSIPMT_Idea_lab_white.png"
import { TiSocialFacebook, TiSocialTwitter } from "react-icons/ti"
import { AiOutlineGoogle } from "react-icons/ai"
import { Link } from "react-router-dom"


const Footer = () => {

    return (
        <>

            <Row className="footerbg">
                <Row className="mb-5">
                    <Col sm={1}></Col>

                    <Col sm={3} className="ml-0 mx-5">
                        <Row className="mt-5 ">
                            <img src={footerlogo} alt="" className="footerlogo" />
                        </Row>
                        <Row className="footerlefttxt mt-3 mr-5">
                        To Transform Technical Education by Empowering Young Minds with Current Technologies, Processes, and Skills for Atmanirbhar Bharat.
                        </Row>
                        <Row className="mt-5 ml-0">
                            <span className="footersocialiconbg ml-0 mr-0">
                                <TiSocialFacebook />
                            </span>
                            <span className="footersocialiconbg ml-2 mr-0">
                                <AiOutlineGoogle />
                            </span>
                            <span className="footersocialiconbg ml-2">
                                <TiSocialTwitter />
                            </span>
                        </Row>
                    </Col>
                    <Col sm className=" mx-5 mt-0">
                        <Row className="footercenterheadingtxt mt-5 ">
                            Quick Links
                        </Row>
                        <Link to="/">
                            <Row className="footercentercentertxt mt-4 ">
                                Home
                            </Row>
                        </Link>
                        <Link to="/Aboutus">
                            <Row className="footercentercentertxt mt-3 ">
                                About us
                            </Row>
                        </Link>
                        {/* <Link to="/uc">
                            <Row className="footercentercentertxt mt-3 ">
                                Blog
                            </Row>
                        </Link> */}
                        {/* <Link to="/Team">
                            <Row className="footercentercentertxt mt-3 ">
                                Team
                            </Row>
                        </Link> */}
                        <Link to="/Services">
                            <Row className="footercentercentertxt mt-3 ">
                                Services
                            </Row>
                        </Link>
                        {/* <Link to="/">
                            <Row className="footercentercentertxt mt-3 ">
                                Disclaimer
                            </Row>
                        </Link> */}
                        {/* <Link to="/Contactus">
                            <Row className="footercentercentertxt mt-3 ">
                                Contact
                            </Row>
                        </Link> */}
                    </Col>
                    <Col sm className="mx-5 mt-0">
                        <Row className="footercenterheadingtxt mt-5 ">
                            Useful Links
                        </Row>
                        <a href="https://ssipmt.com/" target="_blank">
                            <Row className="footercentercentertxt mt-4 ">
                                SSIPMT
                            </Row>
                        </a>
                        <a href="https://idealnet.aicte-india.org/" target="_blank">
                            <Row className="footercentercentertxt mt-4 ">
                                AICTE IDEA Lab
                            </Row>
                        </a>
                        <a href="https://www.aicte-india.org/" target="_blank">
                            <Row className="footercentercentertxt mt-4 ">
                                AICTE
                            </Row>
                        </a>
                    </Col>
                    <Col sm className="mx-5 mt-0">
                        <Row className="footercenterheadingtxt1 mt-5 ">
                            Contact Info
                        </Row>
                        <Row className="footercentercentertxt1 mt-4 ">
                            Old Dhamtari Road,<br />P.O.: Sejabahar, Mujgahan,<br />Raipur, Chhattisgarh
                        </Row>
                        <Row className="footercentercentertxt1new mt-3 "
                            onClick={() => { document.location.href = "tel:+919xxxxxxxxx" }}
                        >
                            0771-3501600 / 01
                        </Row>
                        <Row className="footercentercentertxt1 mt-3 ">
                            info@ssipmt.com
                        </Row>
                    </Col>
                    <Col sm={1}></Col>
                </Row>



                <Row className="footerhzline mt-4">

                    <Col sm={1}></Col>
                    <Col className="ml-0">
                        <Row className="footerlefttxt">
                            <Col>
                                © Copyright 2022 SSIPMT Idea Lab
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4} className="mx-0">
                        <span className="footercentercentertxt mx-3">
                            Terms of Service
                        </span>
                        <span className="footprivacymarginleft"></span>
                        <span className="footercentercentertxt  mx-3">
                            Privacy Policy
                        </span>
                    </Col>
                    <Col sm={1}></Col>

                </Row>

            </Row>

        </>
    )
}

export default Footer