import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navigation from "./Navigation"
import { Row, Col } from "react-bootstrap"
import dividerimg from "./Images/divider.png"
// import aboutimg from "./Images/SSIPMT_logo.png"
// import mission from "./Images/mission.png"
// import vision from "./Images/vision.png"
// import valuesimg from "./Images/value.png"
import Chief_Patron from "./Images/Team/Core Members/Chief Patron.jpg"
import Chief_Mentor from "./Images/Team/Core Members/Chief Mentor.jpg"
import Coordinator from "./Images/Team/Core Members/Coordinator.jpg"
import Co_Coordinator from "./Images/Team/Core Members/Co-Coordinator.jpeg"
import Technical_Guru_1 from "./Images/Team/Core Members/Technical Guru- Mr Pankaj Yadav.jpeg"
import Technical_Guru_2 from "./Images/Team/Core Members/Technical Guru- Mr Arun Kumar.jpeg"
import Technical_Guru_3 from "./Images/Team/Core Members/Technical Guru- Mr Prakash Sahu.jpeg"
import Technical_Guru_4 from "./Images/Team/Core Members/Technical Guru- Mr Arind Singh Solanki.jpeg"
import Technical_Assistant_1 from "./Images/Team/Core Members/Technical Assistant- Rakesh Nishad.jpeg"
import Technical_Assistant_2 from "./Images/Team/Core Members/Technical Assistant- Shailesh Prajapati.jpeg"
import Technical_Assistant_3 from "./Images/Team/Core Members/Technical Assistant- Thakur Prasad Yadav.jpeg"
import Student_Ambassadors_1 from "./Images/Team/Student Ambassadors/Arin Soni.jpeg"
import Student_Ambassadors_2 from "./Images/Team/Student Ambassadors/Aryan Kotwani.jpg"
import Student_Ambassadors_3 from "./Images/Team/Student Ambassadors/Ashutosh Singh.jpg"
import Student_Ambassadors_4 from "./Images/Team/Student Ambassadors/Avneesh Awadhaiya.jpeg"
import Student_Ambassadors_5 from "./Images/Team/Student Ambassadors/Gunja Kurre.jpeg"
import Student_Ambassadors_6 from "./Images/Team/Student Ambassadors/Harsh Singh.jpeg"
import Student_Ambassadors_7 from "./Images/Team/Student Ambassadors/Harshika Meshram.jpg"
import Student_Ambassadors_8 from "./Images/Team/Student Ambassadors/Harshita Amlani.jpg"
import Student_Ambassadors_9 from "./Images/Team/Student Ambassadors/Harshita Singh.jpg"
import Student_Ambassadors_10 from "./Images/Team/Student Ambassadors/Hemprakash Sahu.jpg"
import Student_Ambassadors_11 from "./Images/Team/Student Ambassadors/Kunal Dewangan.jpg"
import Student_Ambassadors_12 from "./Images/Team/Student Ambassadors/Mayank Patoliya.jpg"
import Student_Ambassadors_13 from "./Images/Team/Student Ambassadors/MD Sameer Ansari.jpg"
import Student_Ambassadors_14 from "./Images/Team/Student Ambassadors/Mouli Tripathi.jpg"
import Student_Ambassadors_15 from "./Images/Team/Student Ambassadors/Naman Vora.png"
import Student_Ambassadors_16 from "./Images/Team/Student Ambassadors/Naveen Chandra.png"
import Student_Ambassadors_17 from "./Images/Team/Student Ambassadors/Om Mishra.jpg"
import Student_Ambassadors_18 from "./Images/Team/Student Ambassadors/Omkar Prasad Ijardar.jpg"
import Student_Ambassadors_19 from "./Images/Team/Student Ambassadors/Pradyumn Shukla.jpg"
import Student_Ambassadors_20 from "./Images/Team/Student Ambassadors/Prince Raj.jpg"
import Student_Ambassadors_21 from "./Images/Team/Student Ambassadors/Rishabh Kumar Nirmalkar.jpg"
import Student_Ambassadors_22 from "./Images/Team/Student Ambassadors/Roshan Pal.jpg"
import Student_Ambassadors_23 from "./Images/Team/Student Ambassadors/Sakshi Tikariha.jpg"
import Student_Ambassadors_24 from "./Images/Team/Student Ambassadors/Shivam Verma.jpg"
import Student_Ambassadors_25 from "./Images/Team/Student Ambassadors/Shivansh Kurm.jpg"
import Student_Ambassadors_26 from "./Images/Team/Student Ambassadors/Siddhant Chawla.jpg"
import Student_Ambassadors_27 from "./Images/Team/Student Ambassadors/Srijan Agrawal.jpg"
import Student_Ambassadors_28 from "./Images/Team/Student Ambassadors/Suyash Agrawal.jpg"
import Student_Ambassadors_29 from "./Images/Team/Student Ambassadors/Vibhavari Phaye.jpg"
import Student_Ambassadors_30 from "./Images/Team/Student Ambassadors/Vikas Kumar Matsyapal.jpg"
import Student_Ambassadors_31 from "./Images/Team/Student Ambassadors/VIKAS KUMAR.jpg"
import Student_Ambassadors_32 from "./Images/Team/Student Ambassadors/Vikash Singh Thakur.jpg"
import Student_Ambassadors_33 from "./Images/Team/Student Ambassadors/Yash Pede.jpg"
import Student_Ambassadors_34 from "./Images/Team/Student Ambassadors/Yash Rajput.jpg"
// import teamimg1 from "./Images/Team 1.png"
// import teamimg2 from "./Images/Team 2.png"
// import teamimg3 from "./Images/Team 2.png"
// import teamimg4 from "./Images/Team 2.png"
// import teamimg5 from "./Images/Team 2.png"
// import puzzzleimg from "./Images/icon-puzzle-1.png"
// import { FaCheck } from "react-icons/fa"

const Team = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className=" aboutustopbg justify_content_center">
                <Col className="">
                    <Row className="justify_content_center aboutheadingtxt">
                        Team
                    </Row>

                    <Row className="justify_content_center ">
                        <Col className="justify_content_center ">

                            <span className="aboutustoptext1 mr-0"><Link to="/" className="aboutustoptext1">Home</Link></span>
                            <span className="aboutustoptext1 mx-3 mr-3">{">"}</span>
                            <span className="aboutustoptext2 ml-0">Our Team</span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="homfinmgmthead justify_content_center mt-5">
                Our Team
            </Row>

            <Row className=" justify_content_center mt-2">
                <img src={dividerimg} alt="" className="dividerimg" />
            </Row>

            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Chief_Patron} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Nishant Tripahi</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Cheif Patron</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Chief_Mentor} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Dr. Alok Kumar Jain</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">
                        Chief Mentor
                        {/* Principal */}
                    </Row>
                </Col>

                <Col sm={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>

                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Coordinator} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Dr. J P Patra</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Co-ordinator</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Co_Coordinator} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Atul Chakrawarti</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Co Co-ordinator</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm className='homfinmgmthead'>Idea Lab Gurus</Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Technical_Guru_1} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Pankaj Yadav</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Guru</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Technical_Guru_2} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Arun Kumar</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Guru</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Technical_Guru_3} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Arvind Singh Solanki</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Guru</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Technical_Guru_4} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Prakash Sahu</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Guru</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm className='homfinmgmthead'>Idea Lab Technical Assistant</Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Technical_Assistant_1} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Rakesh Nishad</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Technical Assistant</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Technical_Assistant_2} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Shailesh Prajapati</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Technical Assistant</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Technical_Assistant_3} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Thakur Prasad Yadav</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Technical Assistant</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm className='homfinmgmthead'>Student Ambassadors</Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_1} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Arin Soni</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_2} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Aryan Kotwani</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_3} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Ashutosh Singh</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_4} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Avneesh Awadhaiya</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_5} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Gunja Kurre</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_6} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Harsh Singh</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_7} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Harshika Meshram</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_8} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Harshita Amlani</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_9} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Harshita Singh</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_10} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Hemprakash Sahu</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_11} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Kunal Dewangan</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_12} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Mayank Patoliya</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_13} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">MD Sameer Ansari</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_14} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Mouli Tripathi</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_15} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Naman Vora</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_16} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Naveen Chandra</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_17} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Om Mishra</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_18} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Omkar Prasad Ijardar</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_19} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Pradyumn Shukla</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_20} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Prince Raj</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_21} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Rishabh Kumar Nirmalkar</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_22} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Roshan Pal</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_23} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Sakshi Tikariha</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_24} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Shivam Verma</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_25} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Shivansh Kurm</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_26} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Siddhant Chawla</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_27} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Srijan Agrawal</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_28} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Suyash Agrawal</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_29} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Vibhavari Phaye</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_30} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Vikas Kumar Matsyapal</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_31} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. VIKAS KUMAR</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_32} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Vikash Singh Thakur</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_33} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Yash Pede</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_34} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Yash Rajput</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row>
                </Col>
                <Col sm>
                    {/* <Row className="justify_content_center">
                        <img src={teamimg4} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. A</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    {/* <Row className="justify_content_center">
                        <img src={teamimg4} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. A</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="footermargin  mt-5">
                <Footer />
            </Row>

        </div>
    )
}

export default Team