import React, { useState, useEffect } from "react"
import { Row, Col, Modal } from "react-bootstrap"
// import { Link } from "react-router-dom"
// import Widget from './Widget';
import Navigation from "./Navigation"
import logo from "./Images/SSIPMT_Idea_lab_white.png"
import PDC1 from "./Images/Events/Project Development Competition/PDC1.jpeg"
import PDC2 from "./Images/Events/Project Development Competition/PDC2.jpeg"
import PDC3 from "./Images/Events/Project Development Competition/PDC3.jpeg"
import PDC4 from "./Images/Events/Project Development Competition/PDC4.jpeg"
import PDC5 from "./Images/Events/Project Development Competition/PDC5.jpeg"
import PDC6 from "./Images/Events/Project Development Competition/PDC6.jpeg"
import PDC7 from "./Images/Events/Project Development Competition/PDC7.jpeg"
import PDC8 from "./Images/Events/Project Development Competition/PDC8.jpeg"
import PDC9 from "./Images/Events/Project Development Competition/PDC9.jpeg"
import PDC10 from "./Images/Events/Project Development Competition/PDC10.jpeg"
import PDC11 from "./Images/Events/Project Development Competition/PDC11.jpeg"
import PDC12 from "./Images/Events/Project Development Competition/PDC12.jpeg"
import School_project from "./Images/Events/School_project.jpg"
import Projectvideo from "./Images/Project/Projectvideo.mp4"
// import { FcSearch } from "react-icons/fc"
// import { FaRegClock } from "react-icons/fa"
// import img1 from "./Images/Management_Consultancy_Services.png"
// import img2 from "./Images/project_appraisal.png"
// import img3 from "./Images/Audit.png"
import Footer from "./Footer"

const Projects = () => {

    const [rukjlk, setRukjlk] = useState(true)
    const [showprojects, setShowprojects] = useState(false)

    // const project = [
    //     { yt: "m7PsT6CuJgo", name: "123", desc: "Our theme is Patriotic Theme. We have used Tri-Color LEDs to represent our Nation. We have used smoke to give tributes to our SOLDIER." },
    //     { yt: "m7PsT6CuJgo", name: "456", desc: "Our theme is Patriotic Theme. We have used Tri-Color LEDs to represent our Nation. We have used smoke to give tributes to our SOLDIER 2." },
    //     { yt: "m7PsT6CuJgo", name: "789", desc: "Our theme is Patriotic Theme. We have used Tri-Color LEDs to represent our Nation. We have used smoke to give tributes to our SOLDIER 3." },
    // ];

    useEffect(() => {
        setRukjlk(true)

        setTimeout(() => {
            setShowprojects(true)
            setRukjlk(false)
        }, 5000);
    }, [])

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Modal
                size="sm"
                show={rukjlk}
                centered
                aria-labelledby="example-modal-sizes-title-sm"

            >
                <Modal.Header className="">
                    <Row className="">
                        <img src={logo} alt="" className="logoload" />
                    </Row>
                    <Row>Loading....</Row>
                </Modal.Header>
            </Modal>

            {/* <div>
                <Widget />
            </div> */}

            <Row className="mb-5">
                <Navigation />
            </Row>
            <Row className="mt-5"></Row>

            <Row className="bloglisthead1 justify_content_center mt-5">
                View our collection of <br /> <b className="boldBlogHeading">Projects</b>
            </Row>

            {/* <Row className="justify_content_center mt-4">
                <span className="justify_content_center">
                    <input
                        className="blogsearch"
                        placeholder="Search for your favorite topics"
                        type="text"
                    />

                    <FcSearch className="blogsearchicon mt-2" />
                </span>
            </Row> */}

            {
                showprojects
                    ?
                    <>
                        {/* <Row className="mt-5">
                            {

                                project.map((item) => {
                                    <Col sm={4} className="mt-4">
                                        {console.log(item.name)}
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"https://www.youtube.com/embed/" + item.yt}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />

                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                123 {item.name}
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                {item.desc}
                                            </Row>
                                        </Row>
                                    </Col>
                                })

                            }
                        </Row> */}

                        <Row className="justify-content-center mt-5">
                            <Col xs={2}></Col>
                            <Col className="justify-content-center">
                                <Row className="justify-content-center">
                                <video autoplay="" loop="" controls="true" controlsList="nodownload"
                                    className="projecttopvideo justify-content-center"
                                >
                                    <source src={Projectvideo} type="video/mp4" />
                                </video>
                                </Row>
                            </Col>
                            <Col xs={2}></Col>
                        </Row>

                        <Row className="mt-5" >
                            <Col xs={1}></Col>
                            <Col >
                                <Row className="event2000thead justify-content-center">
                                    School Experimental Kits
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    Here in IDEA Lab we have designed some experimental kits for school students of Science background. The kits will help them learn and understand the concepts more easily.
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    Following are the experimental kits we have designed
                                </Row>
                                <Row className="homefinmgmttext mt-3">
                                    <ol>
                                        <li>To Study the working Principle of a PN Junction Diode</li>
                                        <li>To verify Faraday’s law of Electromagnetic Induction</li>
                                        <li>To verify the working of Logic gate</li>
                                        <li>To Study the working of Light Dependent Resistor (LDR) by detecting the intensity of light</li>
                                        <li>To Study the Series and Parallel Combination of Resistors</li>
                                        <li>To Study the working of a Capacitor</li>
                                    </ol>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                        <Row className="eventmobmargin mt-5 ">
                            <Col xs={1}></Col>
                            <Col xs className=''>
                                <Row className='justify-content-center'>
                                    <img src={School_project} alt="" className='event2000img' />
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>

                        <Row className="mt-5">
                            <Col></Col>
                            <Col xs={6} className="homesecondsecborder"></Col>
                            <Col></Col>
                        </Row>

                        <Row className="mt-5" >
                            <Col xs={1}></Col>
                            <Col >
                                <Row className="event2000thead justify-content-center">
                                    Project Development Competition
                                </Row>
                                {/* <Row className="homefinmgmttext mt-3">
                        It was organized by International Aviation Games Board of India in association with Drone Bharat. 4 teams named Garud, Metal to miracle, Phoenix and Wings participated in this event and bagged first prize.
                    </Row> */}
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                        <Row className="eventmobmargin mt-5 ">
                            <Col xs={1}></Col>
                            <Col xs className=''>
                                <Row className=''>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC1} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC2} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC3} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC4} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC5} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC6} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC7} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC8} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC9} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC10} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC11} alt="" className='event2000img' />
                                    </Col>
                                    <Col sm={6} className='mt-5'>
                                        <img src={PDC12} alt="" className='event2000img' />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>

                        <Row className="mt-5">
                            <Col></Col>
                            <Col xs={6} className="homesecondsecborder"></Col>
                            <Col></Col>
                        </Row>

                        <Row className="mt-5">
                            <Col sm={1}></Col>
                            <Col>
                                <Row>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/m7PsT6CuJgo"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Drone Dance Competition | Team 1
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                Our theme is Patriotic Theme. We have used Tri-Color LEDs to represent our Nation. We have used smoke to give tributes to our SOLDIER.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/WL2he3X4RxQ"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Drone Dance Competition | Team 2
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                We have made a customized drone which is capable of flying at a maximum speed of 120km/hr. Our drone dances with beats.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/qVQq7dtHMCQ"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Drone Dance Competition | Team 3
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                "LIGHTNING SHOW WITH DRONE"- Our drone can show RGB blinking light and can have a range up to 2km with a speed of 120 km/hr.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/SgZ7sKTpmE4"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Drone Dance Competition | Team 4
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                We as a team have made our drone which is capable of flying at a maximum speed of 240 km/hr. Here we are presenting before you dronedoll dance in which a doll is fixed on the upper and the lower part of the drone as the drone moves across it appears as if the doll is dancing.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/6SawbVxLYPk"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Board Cleaner
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                We have used Arduino Uno along with L298n motor driver, 12V low rpm DC motor and power supply. By using HC05 Bluetooth chip we can control and move it towards right or left direction to clean the board.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/yddOuYgzVHk"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Clap Controlled Car
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                In this we used variety of electronic equipment like Arduino Uno , Motor Driver, Jumper Wires , LED, Batteries & It's Connectors , Breadboard. It works by just a Clap. On one clap it turns ON and on 2nd clap it turns OFF.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/w3QivSs-hBg"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Game Controller Using IMU
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                We are using mpu6050 module which is having an accelerometer and gyroscope sensor. The Arduino takes the raw values from the sensor and calculates angles like pitch and roll to give analog-like control and transfers the real-time data to the computer through USB.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/ZLO3QJT94Ko"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Portable Vacuum Cleaner
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                It automatically cleans the floor by creating a vacuum and suck in dust particles. It is very cost effective option to other product in market.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/dz2leQY-20A"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Smart Blind Stick Using Arduino
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                In this Smart Blind stick there is a sensor which senses the obstacles from some range. Upon sensing the stick vibrates and makes buzz sound.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/u2W30GrRSIA"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Smart Recycling Wash Basin
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                It is an Automatic Washbasin with bio-sand filter for re-using impure water. It consist of Arduino UNO, Relay module, Bread board, Jumper cables, Water pump, 12V 1A Power adapter, 12V 1A Universal adapter, 3 Containers, Tap.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/4L5daBYOY6A"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Gesture Controlled Contactless Elevator
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                Touchless Elevator Operation feature puts the elevator into shuttle mode allowing it to operate like a vertical escalator, transporting your tenants and visitors from floor to floor, all without them needing to physically contact any buttons inside or outside the elevator.

                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/ft79oesvkYo"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Bluetooth Controlled Robot Car
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                A car based on Arduino platform which can be controlled using Bluetooth and an Android app. when the model is connected to your device via Bluetooth module HC-06.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/yH8oCJ2ZY28"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Egg Incubator
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                An Egg Incubator is a device simulating avian incubation by keeping eggs warm at a particular temperature range and in the correct humidity.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/pHU7X95Dz9s"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Hall Effect Based Train
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                The system uses infrared sensors to detect the arrival and departure of trains at the railway level crossing and Arduino to control the opening/closing of gates.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/LOkZqOKx754"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Joystick Controlled Car
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                In this we have used ln298 driver, buzzer, and joystick for controlling our car and make it move in every direction. Our car can move forward backward turn left and right also 360 rotations.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/ltb3S3y9I_c"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Line Follower Robot
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                Line follower robot senses black line by using sensor and then sends the signal to arduino. Then arduino drives the motor according to sensors' output.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/gO90nVdoim0"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Password Protected Door Lock System
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                The circuit contains Arduino UNO, keypad module, Servo Motor, and LCD display. Arduino controls the complete processes like taking a password from the keypad module, comparing passwords, rotating servo motor, and sending status to the LCD display.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/jsQGTv7bDiQ"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                PID Controlled Line Follower Robot
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                It is basically a robot which follows a particular path or trajectory and decides its own course of action which interacts with obstacle. The path can be a black line on the white floor.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/O9iXTE9PgP0"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                POV Display Clock
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                Persistence of Vision (POV) displays are generally LED displays which ‘show’ images by displaying a section of an image at a given time, in quick rapid succession. The human brain perceives this as display of a continuous image.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/vmdAIwoVpQw"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Automatic Smart Car Barrier System with Arduino
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                It consist of arduino uno, servo motor and ultrasonic sensor. It can be used in toll tax system on highways, car parking system, automatic gates etc.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/WHedQCZJpe0"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Smart Home Integration
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                Home automation is building automation for a home, called a smart home or smart house. It has various multiple smart connected devices throughout the house.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/HGWGPvc28SE"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Visitor Counter
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                In this project, we will make a Bidirectional Visitor Counter with an Automatic Light Control System using Arduino. This project is based on a pair of Infrared Sensor that detects interrupt when it detects an obstacle.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/TWtnn2XMY8E"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Gesture Based Robotic Arm
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                It is based on Arduino UNO board. It detects the movement of the hand controlling the robotic arm with the gloves and through gyroscope sensor and responds accordingly. This project has various uses in Manufacturing for lifting heavy materials etc.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/p845PW37jy4"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Human Following Robot
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                This Human Following Robot detects and follows the person. It consists of Arduino Uno and Ultrasonic sensor. It follows the person who is standing in front of it.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/oosWxNeM2ms"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Pulse Oximeter with Heart rate monitoring
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                This device measures heart rate and blood oxygen level. When we put finger on sensor the lcd screen displays heart rate in beats per minute and and oxygen level in percentage. This is also displayed in serial monitor.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/pracntXYfZE"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                RFID Based Attendance System
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                This uses RFID technology and Arduino to make a note of every student entering into the classroom and also to calculate the time he/she resides in the class. This system can also be used in offices, hospitals, industries, etc.
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col sm={4} className="mt-4">
                                        <Row className="bloglistcard_new">
                                            <Row >
                                                <iframe src={"//www.youtube.com/embed/8xfzwizsMAo"}

                                                    frameborder="0" allowfullscreen="allowfullscreen" />
                                                {/* <img src={img1} alt="" className="bloglistimg_new" /> */}
                                            </Row>
                                            <Row className="bloglisthead mt-3">
                                                Smart Dustbin
                                            </Row>
                                            <Row className="bloglistdesc mt-2">
                                                The smart dustbin is carefully designed solution that solves the social issue of waste disposal. It works like when you come in a front of this dustbin, it will open automatically with the help of servo motor.
                                            </Row>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>

                        <Row className="mt-5">
                            <Footer />
                        </Row>

                    </>
                    :
                    null
            }
        </>
    )
}

export default Projects