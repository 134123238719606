import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navigation from "./Navigation"
import { Row, Col } from "react-bootstrap"
import drone1 from "./Images/Students_Achievements/Drone Dance Competition/drone1.png"
import drone2 from "./Images/Students_Achievements/Drone Dance Competition/drone2.png"
import drone3 from "./Images/Students_Achievements/Drone Dance Competition/drone3.png"
import drone4 from "./Images/Students_Achievements/Drone Dance Competition/drone4.png"
import sih2022 from "./Images/Students_Achievements/SIH-2022/SIH-2022.jpg"
import ARIA from "./Images/Students_Achievements/ARAI Technovuus Mobility Hackathon/ARIA.jpg"
import H_social from "./Images/Students_Achievements/H Social Creator-2021-Program/H_social.jpeg"
import OPJU from "./Images/Students_Achievements/OPJU Raigarh/OPJU.JPG"
import IITBTechfest1 from "./Images/Students_Achievements/Techfest at IIT Bombay/IITBTechfest1.jpg"
import IITBTechfest2 from "./Images/Students_Achievements/Techfest at IIT Bombay/IITBTechfest2.jpeg"
import IITK1 from "./Images/Students_Achievements/Kshitij Techfest at IIT Kharagpur/IITK1.jpg"
import IITK2 from "./Images/Students_Achievements/Kshitij Techfest at IIT Kharagpur/IITK2.jpg"
import IITK3 from "./Images/Students_Achievements/Kshitij Techfest at IIT Kharagpur/IITK3.jpeg"
import IITK4 from "./Images/Students_Achievements/Kshitij Techfest at IIT Kharagpur/IITK4.JPG"

const Achievement = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className=" acievmenttopbg justify_content_center">
                <Col className="">
                    <Row className="justify_content_center aboutheadingtxt">
                        Student Achievements
                    </Row>

                    <Row className="justify_content_center ">
                        <Col className="justify_content_center ">
                            <span className="aboutustoptext1 mr-0"><Link to="/" className="aboutustoptext1">Home</Link></span>
                            <span className="aboutustoptext1 mx-3 mr-3">{">"}</span>
                            <span className="aboutustoptext2 ml-0">Student Achievements</span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-5" >
                <Col xs={1}></Col>
                <Col >
                    <Row className="event2000thead justify-content-center">
                        Drone Dance Competition, 2022
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        It was organized by International Aviation Games Board of India in association with Drone Bharat. 4 teams named Garud, Metal to miracle, Phoenix and Wings participated in this event and bagged first prize.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="eventmobmargin mt-5 ">
                <Col xs={1}></Col>
                <Col xs className=''>
                    <Row className=''>
                        <Col sm={6} className='mt-5'>
                            <img src={drone1} alt="" className='event2000img' />
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <img src={drone2} alt="" className='event2000img' />
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <img src={drone3} alt="" className='event2000img' />
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <img src={drone4} alt="" className='event2000img' />
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5" >
                <Col xs={1}></Col>
                <Col >
                    <Row className="event2000thead justify-content-center">
                        Smart India Hackathon(SIH),2022
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        It was organized by Ministry of Education (MoE) and AICTE. Our Students got first prize in one of the problem statement stated by SIH.
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        <b>Problem Statement: </b>Rapid Decomposition of waste in rural areas
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        <b>Solution : </b>We used rice and coconut husk for plywood to reduce our dependence on timber.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="eventmobmargin mt-5">
                <Col xs={1}></Col>
                <Col xs >
                    <Row className='justify-content-center'>
                        <img src={sih2022} alt="" className='event2000img' />
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5" >
                <Col xs={1}></Col>
                <Col >
                    <Row className="event2000thead justify-content-center">
                        ARAI Technovuus Mobility Hackathon
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        Four of our Students participated in ARAI Technovuus mobility Hackathon held at Pune and won a paid internship of 6 months along with a trophy and certificate.
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        They created an IoT device and an app which deals with data acquisition and monitoring system for electric vehicle.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="eventmobmargin mt-5">
                <Col xs={1}></Col>
                <Col xs className=''>
                    <Row className='justify-content-center'>
                        <img src={ARIA} alt="" className='event2000img' />
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5" >
                <Col xs={1}></Col>
                <Col >
                    <Row className="event2000thead justify-content-center">
                        H Social Creator 2021 Program
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        2 of our Students participated in H Social creator program 2021 and were the runner ups. Our Students designed a variable pitch horn system to control noise pollution. The speed of vehicle is determined and on its basis the pitch and amplitude of horn will be changed.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="eventmobmargin mt-5">
                <Col xs={1}></Col>
                <Col xs className=''>
                    <Row className='justify-content-center'>
                        <img src={H_social} alt="" className='event2000img' />
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5" >
                <Col xs={1}></Col>
                <Col >
                    <Row className="event2000thead justify-content-center">
                        National Innovation Fest 2.0, OPJU, Raigarh
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        Our Students designed a variable pitch horn system to control noise pollution.The speed of vehicle is determined and on its basis the pitch and amplitude of horn will be changed.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="eventmobmargin mt-5">
                <Col xs={1}></Col>
                <Col xs className=''>
                    <Row className='justify-content-center'>
                        <img src={OPJU} alt="" className='event2000img' />
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5" >
                <Col xs={1}></Col>
                <Col >
                    <Row className="event2000thead justify-content-center">
                        Techfest at IIT Bombay
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        We have build an autonomous, self-contained robot, Micromouse, which can get to the centre of a maze in the shortest possible time. A micromouse essentially comprises of a drive motor or motors to move it; a steering and turning method; sensors to detect the presence or absence of maze walls; sensors or control logic to oversee the action of the rest and keep the vehicle 'on track' or to solve the maze; batteries to provide power.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="eventmobmargin mt-5">
                <Col xs={1}></Col>
                <Col xs className=''>
                    <Row >
                        <Col sm={6} className='mt-5'>
                            <img src={IITBTechfest1} alt="" className='event2000img' />
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <img src={IITBTechfest2} alt="" className='event2000img' />
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5" >
                <Col xs={1}></Col>
                <Col >
                    <Row className="event2000thead justify-content-center">
                        Kshitij Techfest at IIT Kharagpur
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        Student Ambassadors of IDEA Lab participated in 2 events of Kshitij, 2022. The event were Laws of Motion and Sandrover. The Laws of motion team bagged 3rd position and were qualified for the next round to be held in Bangalore.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="eventmobmargin mt-5">
                <Col xs={1}></Col>
                <Col xs className=''>
                    <Row >
                        <Col sm={6} className='mt-5'>
                            <img src={IITK1} alt="" className='event2000img' />
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <img src={IITK2} alt="" className='event2000img' />
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <img src={IITK3} alt="" className='event2000img' />
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <img src={IITK4} alt="" className='event2000img' />
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="footermargin  mt-5">
                <Footer />
            </Row>

        </div >
    )
}

export default Achievement