
import './App.css';
import { HashRouter as Router, Routes, Route } from "react-router-dom"
import { Container } from 'react-bootstrap';
import Homepage from "./Components/Homepage"
import Aboutus from './Components/Aboutus';
import Services from './Components/Services';
import Contactus from './Components/Contactus';
import Patents from './Components/Patents';
import Projects from './Components/Projects';
import Gallery from './Components/Gallery';
import Team from './Components/Team';
import Facilities from './Components/Facilities';
import Events2022 from './Components/Events2022';
import Media from './Components/Media';
import Drone from './Components/Drone';
import Achievement from './Components/Achievement';
import Guests from './Components/Guests';

function App() {
  return (
    <div className="">
      <Container fluid className="">
      <Router>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/Aboutus" element={<Aboutus />} />
          <Route exact path="/Team" element={<Team />} />
          <Route exact path="/Services" element={<Services />} />
          <Route exact path="/Projects" element={<Projects />} />
          <Route exact path="/Gallery" element={<Gallery />} />
          <Route exact path="/Contactus" element={<Contactus />} />
          <Route exact path="/Patents" element={<Patents />} />
          <Route exact path="/Facilities" element={<Facilities />} />
          <Route exact path="/Events" element={<Events2022 />} />
          <Route exact path="/Drone" element={<Drone />} />
          <Route exact path="/Achievement" element={<Achievement />} />
          <Route exact path="/Guests" element={<Guests />} />
          <Route exact path="/Mediacoverage" element={<Media />} />         
        </Routes>
      </Router>
      </Container>
    </div>
  );
}

export default App;
