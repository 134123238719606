import React, {useEffect} from "react"
import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navigation from "./Navigation"
import { Row, Col } from "react-bootstrap"
import servimg1 from "./Images/Audit.png"
import servimg2 from "./Images/services_right.png"
import servimg3 from "./Images/Management_Consultancy_Services.png"
import servimg4 from "./Images/project_appraisal.png"
import servimg5 from "./Images/corporate_law.png"
import { FaCheck } from "react-icons/fa"

const Services = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className=" servicestopbg justify_content_center">
                <Col className="">
                    <Row className="justify_content_center aboutheadingtxt">

                        Services

                    </Row>

                    <Row className="justify_content_center ">
                        <Col className="justify_content_center ">

                            <span className="aboutustoptext1 mr-0"><Link to="/" className="aboutustoptext1">Home</Link></span>
                            <span className="aboutustoptext1 mx-3 mr-3">{">"}</span>
                            <span className="aboutustoptext2 ml-0">Services</span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* <Row className="serviceshead justify_content_center mt-4">
                We Will Provide Best Tax Service For Your Business
            </Row>
            <Row className="mt-4 ">
                <Col xs={3}></Col>
                <Col className="homesecondsecborder"></Col>
                <Col xs={3}></Col>
            </Row>
            <Row className="homesecondsecdesc justify_content_center mt-4 mx-2">
                <Col xs={2}></Col>
                <Col > The clientele to whom services are provided ranges from proprietor to company form of organization. The recent exposures are as follows:-
                </Col>
                <Col xs={2}></Col>
            </Row>
            <Row className="homesecondsecdesc  mt-4 mx-2">
                <Col xs={2}></Col>
                <Col >
                    <ul>
                        <li>Serving as Empanelled Stock Auditor of Union Bank of India.</li>
                        <li>Since 2 years serving as stock auditors to NSPCL(A Joint Venture of NTPC and SAIL).</li>
                        <li>Providing services of Compliance to International Remittances regarding withholding Taxation.</li>
                    </ul>
                </Col>
                <Col xs={2}></Col>
            </Row>
            <Row className="homesecondsecdesc justify_content_center mt-4 mx-2">
                <Col xs={2}></Col>
                <Col > Other services available with us are as below:-</Col>
                <Col xs={2}></Col>
            </Row>
 */}

            <Row className=" justify_content_center mt-5 mb-5 " >
                <Col sm={1}></Col>
                <Col sm className="bloglistcenterborder">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={servimg1} className="bloglistimg" alt="" />
                            </Row>

                        </Col>
                    </Row>
                </Col>
                <Col sm className="">
                    <Row className=" mx-5">
                        <Row className="bloglisthead mt-3">
                            Audit & Assurance Services
                        </Row>
                        <Row className="homesecondsecdesc mt-4 mx-2">
                            We are a team of qualified management consultant highly efficient in auditing and assurance services and have exposure in almost all forms of organizations. Our wide ranges of services are aimed for financial stability of the clients and take care of the decision making abilities. The services we provide are in comply with the legal environment.
                        </Row>
                        <Row className="mt-4">
                            <Row className="homesecondsecbullets" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col> Statutory Audit</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Internal Audit</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Government audits (Continous audit for 5 years)</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Other special assignments from state government and local authorities</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>System and Management audit</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Concurrent and Income & Expenditure Audit (Colleges, BRGF, Schools etc.)</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Statutory Bank Audit</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Stock Audit</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Certification Work</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Large experience in audits of core financial sector corporate</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Almost all types of audit and inspection assignments relating to Banks</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Experience of audits in technology and other technology enabled business establishments</Col>
                            </Row>
                        </Row>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-4 ">
                <Col xs={4}></Col>
                <Col className="homesecondsecborder"></Col>
                <Col xs={4}></Col>
            </Row>
            <Row className=" justify_content_center mt-5 mb-5 " >
                <Col sm={1}></Col>
                <Col sm className="bloglistcenterborder">
                    <Row className=" mx-5">
                        <Row className="bloglisthead mt-3">
                            Taxation (Direct and Indirect taxation)
                        </Row>
                        <Row className="homesecondsecdesc mt-4 mx-2">
                            We specialize in providing taxation services to individuals, small scale and medium scale businesses which are highly effective and efficient. Our range of services includes:
                        </Row>
                        <Row className="mt-4">
                            <Row className="homesecondsecbullets" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Preparation of return</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Getting Assessment done</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Consultancy in tax matters & tax planning</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Foreign taxation consultancy</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>TDS & Withholding tax compliance</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Good and Sales Tax (GST), record keeping, regular compliances and Assessment.</Col>
                            </Row>
                        </Row>
                    </Row>
                </Col>
                <Col sm className="">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={servimg2} className="bloglistimg" alt="" />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-4 ">
                <Col xs={4}></Col>
                <Col className="homesecondsecborder"></Col>
                <Col xs={4}></Col>
            </Row>
            <Row className=" justify_content_center mt-5 mb-5 " >
                <Col sm={1}></Col>
                <Col sm className="bloglistcenterborder">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={servimg3} className="bloglistimg" alt="" />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm className="">
                    <Row className=" mx-5">
                        <Row className="bloglisthead mt-3">
                            Management Consultancy Services
                        </Row>
                        <Row className="homesecondsecdesc mt-4 mx-2">
                            We being a team of managemnet consultant having exposure in versatile aspects of business, our range of services also includes value additive expertise in:
                        </Row>
                        <Row className="mt-4">
                            <Row className="homesecondsecbullets" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Developing Management Information System.</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Designing budgetary and control system.</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Determining measures of the effective utilization of capital.</Col>
                            </Row>
                        </Row>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-4 ">
                <Col xs={4}></Col>
                <Col className="homesecondsecborder"></Col>
                <Col xs={4}></Col>
            </Row>
            <Row className=" justify_content_center mt-5 mb-5 " >
                <Col sm={1}></Col>
                <Col sm className="bloglistcenterborder">
                    <Row className=" mx-5">
                        <Row className="bloglisthead mt-3">
                            Project Appraisal/ Reporting & Finance Intermediation
                        </Row>
                        <Row className="homesecondsecdesc mt-4 mx-2">
                            We have years of experience in providing business financial services to our clients. Our experts carefully analyze the current situation and accordingly prepare the project reports. Loan arrangement needs are also well taken care by us including cash credit limit, term loan and project loan. We also offer due diligence for finance and valuation of shares.
                        </Row>
                        <Row className="mt-4">
                            <Row className="homesecondsecbullets" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Preparation of project report</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Loan arrangement-Cash credit limit, Term loan & Project loan</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Due diligence for finance</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Valuation of shares</Col>
                            </Row>
                        </Row>
                    </Row>
                </Col>
                <Col sm className="">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={servimg4} className="bloglistimg" alt="" />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-4 ">
                <Col xs={4}></Col>
                <Col className="homesecondsecborder"></Col>
                <Col xs={4}></Col>
            </Row>
            <Row className=" justify_content_center mt-5 mb-5 " >
                <Col sm={1}></Col>
                <Col sm className="bloglistcenterborder">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={servimg5} className="bloglistimg" alt="" />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm className="">
                    <Row className=" mx-5">
                        <Row className="bloglisthead mt-3">
                            Corporate Law matters
                        </Row>
                        <Row className="homesecondsecdesc mt-4 mx-2">
                            Every company according to the India law need to register with the registrar of companies and it is also necessary with documents for various statutory requirements. We offer to our clients with the company law matters consultancy. Our wide range of products include merger and acquisitions, reconstructing, maintenance and compliance of statutory records etc.
                        </Row>
                        <Row className="mt-4">
                            <Row className="homesecondsecbullets" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Formation of company in India, viz, Private Limited, Public Limited, wholly owned subsidiary company, LLP etc.</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>All matters related with Registrar of Companies & Company Law Board</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Maintenance & compliance of Statutory Records</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Merger & acquisitions</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Corporate restructuring</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col>Consultancy in above matters.</Col>
                            </Row>
                        </Row>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="footermargin  ">
                <Footer />
            </Row>

        </div>
    )
}

export default Services