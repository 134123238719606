import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navigation from "./Navigation"
import { Row, Col } from "react-bootstrap"
//import aboutimg from "./Images/SSIPMT_logo.png"
import aboutimg from "./Images/SSIPMT_Idea_lab.png"
// import mission from "./Images/mission.png"
// import vision from "./Images/vision.png"
import Chief_Patron from "./Images/Team/Core Members/Chief Patron.jpg"
import Chief_Mentor from "./Images/Team/Core Members/Chief Mentor.jpg"
import gurinder from "./Images/Team/Core Members/Gurinder Pal.jpg"
import hirdesh from "./Images/Team/Core Members/Hirdesh Singhal.jpeg"
import Coordinator from "./Images/Team/Core Members/Coordinator.jpg"
import Co_Coordinator from "./Images/Team/Core Members/Co-Coordinator.jpg"
import Technical_Guru_1 from "./Images/Team/Core Members/Technical Guru- Mr Pankaj Yadav.jpeg"
import Technical_Guru_2 from "./Images/Team/Core Members/Technical Guru- Mr Arun Kumar.jpeg"
import Technical_Guru_3 from "./Images/Team/Core Members/Technical Guru- Mr Prakash Sahu.jpeg"
import Technical_Guru_4 from "./Images/Team/Core Members/Technical Guru- Mr Arind Singh Solanki.jpeg"
import Technical_Assistant_1 from "./Images/Team/Core Members/Technical Assistant- Rakesh Nishad.jpeg"
import Technical_Assistant_2 from "./Images/Team/Core Members/Technical Assistant- Shailesh Prajapati.jpeg"
import Technical_Assistant_3 from "./Images/Team/Core Members/Technical Assistant- Thakur Prasad Yadav.jpeg"
import Student_Ambassadors_1 from "./Images/Team/Student Ambassadors/Arin Soni.jpeg"
import Student_Ambassadors_2 from "./Images/Team/Student Ambassadors/Aryan Kotwani.jpg"
import Student_Ambassadors_3 from "./Images/Team/Student Ambassadors/Ashutosh Singh.jpg"
import Student_Ambassadors_4 from "./Images/Team/Student Ambassadors/Avneesh Awadhaiya.jpeg"
import Student_Ambassadors_5 from "./Images/Team/Student Ambassadors/Gunja Kurre.jpeg"
import Student_Ambassadors_6 from "./Images/Team/Student Ambassadors/Harsh Singh.jpeg"
import Student_Ambassadors_7 from "./Images/Team/Student Ambassadors/Harshika Meshram.jpg"
import Student_Ambassadors_8 from "./Images/Team/Student Ambassadors/Harshita Amlani.jpg"
import Student_Ambassadors_9 from "./Images/Team/Student Ambassadors/Harshita Singh.jpg"
import Student_Ambassadors_10 from "./Images/Team/Student Ambassadors/Hemprakash Sahu.jpg"
import Student_Ambassadors_11 from "./Images/Team/Student Ambassadors/Kunal Dewangan.jpg"
import Student_Ambassadors_12 from "./Images/Team/Student Ambassadors/Mayank Patoliya.jpg"
import Student_Ambassadors_13 from "./Images/Team/Student Ambassadors/MD Sameer Ansari.jpg"
import Student_Ambassadors_14 from "./Images/Team/Student Ambassadors/Mouli Tripathi.jpg"
import Student_Ambassadors_15 from "./Images/Team/Student Ambassadors/Naman Vora.png"
import Student_Ambassadors_16 from "./Images/Team/Student Ambassadors/Naveen Chandra.png"
import Student_Ambassadors_17 from "./Images/Team/Student Ambassadors/Om Mishra.jpg"
import Student_Ambassadors_18 from "./Images/Team/Student Ambassadors/Omkar Prasad Ijardar.jpg"
import Student_Ambassadors_19 from "./Images/Team/Student Ambassadors/Pradyumn Shukla.jpg"
import Student_Ambassadors_20 from "./Images/Team/Student Ambassadors/Prince Raj.jpg"
import Student_Ambassadors_21 from "./Images/Team/Student Ambassadors/Rishabh Kumar Nirmalkar.jpg"
import Student_Ambassadors_22 from "./Images/Team/Student Ambassadors/Roshan Pal.jpg"
import Student_Ambassadors_23 from "./Images/Team/Student Ambassadors/Sakshi Tikariha.jpg"
import Student_Ambassadors_24 from "./Images/Team/Student Ambassadors/Shivam Verma.jpg"
import Student_Ambassadors_25 from "./Images/Team/Student Ambassadors/Shivansh Kurm.jpg"
import Student_Ambassadors_26 from "./Images/Team/Student Ambassadors/Siddhant Chawla.jpg"
import Student_Ambassadors_27 from "./Images/Team/Student Ambassadors/Srijan Agrawal.jpg"
import Student_Ambassadors_28 from "./Images/Team/Student Ambassadors/Suyash Agrawal.jpg"
import Student_Ambassadors_29 from "./Images/Team/Student Ambassadors/Vibhavari Phaye.jpg"
import Student_Ambassadors_30 from "./Images/Team/Student Ambassadors/Vikas Kumar Matsyapal.jpg"
import Student_Ambassadors_31 from "./Images/Team/Student Ambassadors/VIKAS KUMAR.jpg"
import Student_Ambassadors_32 from "./Images/Team/Student Ambassadors/Vikash Singh Thakur.jpg"
import Student_Ambassadors_33 from "./Images/Team/Student Ambassadors/Yash Pede.jpg"
import Student_Ambassadors_34 from "./Images/Team/Student Ambassadors/Yash Rajput.jpg"
// import valuesimg from "./Images/value.png"
// import teamimg1 from "./Images/Team 1.png"
// import teamimg2 from "./Images/Team 2.png"
// import teamimg3 from "./Images/Team 2.png"
// import teamimg4 from "./Images/Team 2.png"
// import teamimg5 from "./Images/Team 2.png"
// import puzzzleimg from "./Images/icon-puzzle-1.png"
// import { FaCheck } from "react-icons/fa"

const Aboutus = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className=" aboutustopbg justify_content_center">
                <Col className="">
                    <Row className="justify_content_center aboutheadingtxt">

                        About Us

                    </Row>

                    <Row className="justify_content_center ">
                        <Col className="justify_content_center ">

                            <span className="aboutustoptext1 mr-0"><Link to="/" className="aboutustoptext1">Home</Link></span>
                            <span className="aboutustoptext1 mx-3 mr-3">{">"}</span>
                            <span className="aboutustoptext2 ml-0">About us</span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className=" justify_content_center mt-5">
                <Col sm={1}></Col>
                <Col sm className='justify_content_center' >
                    <Row className="">
                        <img src={aboutimg} alt="" className="finmgmtimg1" />
                    </Row>
                </Col>

                <Col sm={1}></Col>
            </Row>

            {/* <Row className=" justify_content_center mt-5">
                <Col sm={1}></Col>
                <Col sm={5} >
                    <Row className="finmmtimgabtbgnew">
                    </Row>
                    <Row className="">
                        <img src={aboutimg} alt="" className="finmgmtimg1" />
                    </Row>
                </Col>
                <Col sm>
                    <Row className="homfinmgmthead mt-3">
                        Who We Are
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        Having its Head Quarter at Bilaspur Chhattisgarh, Beams Group is a poineer and a leading name in Next Generation Wealth Management and Financial Consulting in the region. Powerpacked with the experienced and talented team, we are ready and excited about our contribution to a million more investor's financial journey.
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        Beams Group (Birasini Engineering and Management Services (OPC) Pvt. Ltd. is a DIPPT (Govt. of India) recognized startup which evolved over the years and has an interesting story behind.
                    </Row>                    
                </Col>
                <Col sm={1}></Col>
            </Row> */}

            {/* <Row className="homfinmgmthead justify_content_center mt-5">
                Our Team
            </Row>

            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={teamimg1} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Nishant Tripahi</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Cheif Patron</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={teamimg2} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Atul Chakrawarti</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Co-ordinator</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={teamimg3} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Akash Jain</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Co Co-ordinator</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={teamimg4} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Team 4</Row>
                    <Row className="Teammebdesign justify_content_center mt-1"></Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={teamimg5} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Team 5</Row>
                    <Row className="Teammebdesign justify_content_center mt-1"></Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT Idea Lab</Row>
                </Col>
                <Col sm={1}></Col>
            </Row> */}

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead ">
                        ABOUT THE AICTE
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        All India Council for Technical Education (AICTE) set up in November 1945 is a National-Level Apex Statutory Body of Ministry of Human Resource Development (MHRD), New Delhi. The AICTE is responsible for quality monitoring and standards of technical education, and its planned and coordinated development in the country. It emphasizes developing highquality  institutions, academic excellence, and innovative research, inculcating entrepreneurship, and encouraging indigenous technology. The mission of AICTE is to provide affordable education to all and make Technical Education in India globally acceptable.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead ">
                        ABOUT THE IDEA LAB
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        AICTE-IDEA (IDEA Development, Evaluation & Application) Labs are being established across the country for encouraging students for application of science, technology engineering and mathematics (STEM) fundamentals towards enhanced hands-on experience, learning by doing and even product visualization. As a common facility embedded in the institution, the IDEA Lab will make engineering graduates more imaginative and creative, besides getting basic training in the 21st century skills like- critical thinking, problem solving, design thinking, collaboration, communication, lifelong learning etc. IDEA Lab can empower the students and faculty to “engage, explore, experience, express and excel”, addressing the need of new age learning.
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        IDEA Lab will provide all facilities under one roof, for conversion of an idea into a prototype. The idea need not be always be new (which will always be encouraged) but the emphasis would be on graduating engineers working with their hands using equipment, tools and consumables (listed in the Scheme Document). With these facilities available 24x7 in the campus, more students and faculty will be encouraged to take up creative work and in the process, get training on creative thinking, problem solving, collaboration etc. which conventional labs are not focussing on.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead">
                        ABOUT THE HOST INSTITUTE (SSIPMT, RAIPUR)
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        Shri Shankaracharya Institute of Professional Management & Technology, Raipur is overseen by Shri Gangajali Education Society (SGES), Bhilai. Established under the arrangement of resolution 28, outlined under M.P. Vishwavidyalaya Adhinium 1973 and AICTE act. affiliated to Chhattisgarh Swami Vivekanand Technical University Bhilai. SSIPMT is accredited by two important bodies in Technical Education i.e. NBA and NAAC. It fosters an understanding of technical and management education to harvest skilled engineers and efficient managers for society. The global competitive market requires innovation, transformation and application of bold methodologies, knowledge and determination to challenge conventional practices. SSIPMT has worked round the clock to achieve this aim in the newly-born state of Chhattisgarh. At the same time, this institution draws its strength from our Indian culture. We transform raw minds into professionals through our focus on education, research and training practices.
                    </Row>
                    {/* <Row className="homefinmgmttext mt-3">
                        The Skilling Program, organized by The IDEA Lab at SSIPMT is an endeavor to equip the students with the knowledge of latest technologies. IDEA Lab would serve as an infrastructure for students to take up and promote multidisciplinary education and research. Accordingly, students would be encouraged to get trained in these Labs and strive for creating problems/ projects/ internships in their own subjects/ disciplines.
                    </Row> */}
                </Col>
                <Col xs={1}></Col>
            </Row>

            {/* <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="homfinmgmthead mt-5">
                        Our Philosophy
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        The Soul of our whole work, the core of everything we do lies in our philosophy which is SERVICE. We exist to serve this world. We understand that investor's money is not just an amount but it's their capital that they have earned with their hardwork and sweat and hence it's our duty to protect it, grow it and manage it well.
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        That's why our approach towards wealth management is very accommodative and customer centric in nature. We put you first. We don't offer any straight forward investment basket to you, rather we consult you on what should be the ideal approach which works for you, by analysing your financial profile, your goals and your case overall. Everyone of us has a unique requirement and hence a customized wealth management solution best serves the purpose.
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        Offering this customized wealth management service requires mastery on a vast range of investment options under multiple Asset classes. Our Asset Allocation model helps us allocate capital in appropriate weightage in different -vely or uncorrelated asset classes like Equity, Debt, Commodities, Foreign Equities, Real Estate, Cash, Alternative Asset Classes, etc. With each asset classes having multiple investment tools, we leave no stone unturned to explore the best suitable investment option for you.
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        Come and join us in this rewarding journey!
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row> */}
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row >
                <Col xs={1}></Col>
                <Col className="homfinmgmthead justify_content_center mt-5">Our Team</Col>
                <Col xs={1}></Col>
            </Row>

            {/* <Row className=" justify_content_center mt-2">
                <img src={dividerimg} alt="" className="dividerimg" />
            </Row> */}

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={Chief_Patron} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Nishant Tripahi</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Cheif Patron</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                </Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={Chief_Mentor} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Dr. Alok Kumar Jain</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">
                        Chief Mentor
                    </Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={gurinder} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Gurinder Pal Singh</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">BOG</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT</Row>
                </Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={hirdesh} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Hirdesh Singhal</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Head, Academic Council</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT</Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>

                <Col xs>
                    <Row className="justify_content_center">
                        <img src={Coordinator} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Dr. J P Patra</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Co-ordinator</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                </Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={Co_Coordinator} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Atul Chakrawarti</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Co Co-ordinator</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs className='homfinmgmthead'>IDEA Lab Gurus</Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={Technical_Guru_1} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Pankaj Yadav</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Guru</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                </Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={Technical_Guru_2} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Arun Kumar</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Guru</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                </Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={Technical_Guru_3} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Prakash Sahu</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Guru</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                </Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={Technical_Guru_4} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Arvind Singh Solanki</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Guru</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs className='homfinmgmthead'>IDEA Lab Technical Assistant</Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={Technical_Assistant_1} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Rakesh Nishad</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Technical Assistant</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                </Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={Technical_Assistant_2} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Shailesh Prajapati</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Technical Assistant</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                </Col>
                <Col xs>
                    <Row className="justify_content_center">
                        <img src={Technical_Assistant_3} alt="" className="teamimg" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Thakur Prasad Yadav</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">Technical Assistant</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                </Col>

                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs className='homfinmgmthead'>Student Ambassadors</Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_1} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Arin Soni</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_2} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Aryan Kotwani</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_3} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Ashutosh Singh</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_4} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Avneesh Awadhaiya</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_5} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Gunja Kurre</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_6} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Harsh Singh</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_7} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Harshika Meshram</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_8} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Harshita Amlani</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_9} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Harshita Singh</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_10} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Hemprakash Sahu</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_11} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Kunal Dewangan</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_12} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Mayank Patoliya</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_13} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">MD Sameer Ansari</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_14} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Mouli Tripathi</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_15} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Naman Vora</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_16} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Naveen Chandra</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_17} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Om Mishra</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_18} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Omkar Prasad Ijardar</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_19} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Pradyumn Shukla</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_20} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Prince Raj</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_21} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Rishabh Kumar Nirmalkar</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_22} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Roshan Pal</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_23} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Sakshi Tikariha</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_24} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Shivam Verma</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_25} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Shivansh Kurm</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_26} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Siddhant Chawla</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_27} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Srijan Agrawal</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_28} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Suyash Agrawal</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_29} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Miss. Vibhavari Phaye</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_30} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Vikas Kumar Matsyapal</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_31} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. VIKAS KUMAR</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">Sem, branch</Row> */}
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_32} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Vikash Singh Thakur</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_33} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Yash Pede</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    <Row className="justify_content_center">
                        <img src={Student_Ambassadors_34} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. Yash Rajput</Row>
                    {/* <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    {/* <Row className="justify_content_center">
                        <img src={teamimg4} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. A</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col sm>
                    {/* <Row className="justify_content_center">
                        <img src={teamimg4} alt="" className="team_stu_amb_img" />
                    </Row>
                    <Row className="Teammebname justify_content_center mt-4">Mr. A</Row>
                    <Row className="Teammebdesign justify_content_center mt-1">SSIPMT, Raipur</Row> */}
                </Col>
                <Col xs={1}></Col>
            </Row>

            {/* 
            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm={2}>
                    <img src={vision} alt="" className="visionimg" />
                </Col>
                <Col sm>
                    <Row className="homfinmgmtsubhead ">
                        IDEA Lab Vision
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                    To Transform Technical Education by Empowering Young Minds with Current Technologies, Processes, and Skills for Atmanirbhar Bharat.
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm={2}>
                    <img src={mission} alt="" className="visionimg" />
                </Col>
                <Col sm>
                    <Row className="homfinmgmtsubhead ">
                        IDEA Lab Mission
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="homefinmgmttext justify_content_left" style={{ marginLeft: "-30px" }}>To provide all facilities under one roof, for conversion of an idea into a prototype.</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="homefinmgmttext justify_content_left" style={{ marginLeft: "-30px" }}>To empower students and faculty to take up and promote multidisciplinary education and innovations with aspects of “engage, explore, experience, express and excel”.</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="homefinmgmttext justify_content_left" style={{ marginLeft: "-30px" }}>To encourage students to apply science, technology, engineering, and mathematics (STEM) fundamentals to seek solutions to real-life problems.</Col>
                        </Row>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row > */}

            {/* <Row className="mt-5">
                <Col sm={1}></Col>
                <Col sm={2}>
                    <img src={valuesimg} alt="" className="visionimg" />
                </Col>
                <Col sm>
                    <Row className="homfinmgmtsubhead ">
                        Core Values
                    </Row>
                    <Row className="mt-3">

                        <div className="homefinmgmttext" justify_content_left>
                            <FaCheck className="homesecondsectick" /> Strength
                        </div>
                        <div className="homefinmgmttext mt-3" justify_content_left>
                            <FaCheck className="homesecondsectick" />  Truth
                        </div>
                        <div className="homefinmgmttext mt-3" justify_content_left>
                            <FaCheck className="homesecondsectick" /> Unity
                        </div>
                        <div className="homefinmgmttext mt-3" justify_content_left>
                            <FaCheck className="homesecondsectick" /> Discipline
                        </div>
                        <div className="homefinmgmttext mt-3" justify_content_left>
                            <FaCheck className="homesecondsectick" /> Excellence
                        </div>
                        <div className="homefinmgmttext mt-3" justify_content_left>
                            <FaCheck className="homesecondsectick" /> Network
                        </div>
                        <div className="homefinmgmttext mt-3" justify_content_left>
                            <FaCheck className="homesecondsectick" /> Team Work
                        </div>
                    </Row>

                </Col>
                <Col sm={1}></Col>
            </Row>

            <Row className="">
                <Col sm={1}></Col>
                <Col sm>
                    <Row className="homfinmgmthead mt-5">
                        Quality Objective
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        The fundamental objective of SSIPMT is advancement and dissemination of knowledge. The logo of the college highlights the Iconic line from the Vedas “ज्ञानदेव तू कैवल्यं” which means: Knowledge is Emancipation.
                    </Row>
                    <Row className="homfinmgmthead mt-5">
                        Objectives
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        <ul>
                            <li>To sustain and develop its identity as a teaching institution of high caliber.</li>
                            <li className="mt-3">To provide an outstanding educational environment that supports learning across a broad range of academic disciplines and providing – ‘Industry-ready’ students. </li>
                            <li className="mt-3">To enhance scientific and cultural vision of society as well as its economic well being. </li>
                            <li className="mt-3">To produce engineers & managers equipped with high personal and professional achievements.</li>
                            <li className="mt-3">To inspire students to develop personality traits and become responsible citizens.</li>
                            <li className="mt-3">To inculcate in the students a sense of achievement of rich spiritual heritage and the higher faculties of the mind.</li>

                        </ul>

                    </Row>

                </Col>
                <Col sm={1}></Col>
            </Row> */}

            {/* <Row className=" justify_content_center mt-5 mb-5 " >
                <Col xs={1}></Col>
                <Col data-aos="fade-right">
                    <Row className="homesecondsecwelcom mt-5" >
                        WELCOME TO SSIPMT IDEA Lab
                    </Row>
                    <Row className="homesecondsechead mt-4">
                        We Will Provide Best Service.
                    </Row>
                    <Row className="mt-4">
                        <Col xs={3} className="homesecondsecborder"></Col>
                    </Row>
                    <Row className="homesecondsecdesc mt-4 mx-2">
                    Affirm E Compliance Pvt. Ltd. is a multi service professional organization dedicated to provide a wide range of professional services to its clients across diverse industries. We provide a comprehensive set of Accounting, Finance, Taxation, Outsourcing, Consulting and Management Assurance service solutions to clients pan India, all under one roof.
                    </Row>

                    <Row className="homesecondsecdesc mt-4 mx-2">
                        We deeply care for our clients, firmly believe in our people and are Passionate about our quality of services. We are equipped to meet the changing demands of the corporate and non-corporate entities with our trained personnel, capable of rendering wide spectrum of services.
                    </Row>

                    <Row className="homesecondsecdesc mt-4 mx-2">
                        We believe:
                    </Row>

                    <Row className="mt-4">
                        <Row className="homesecondsecbullets" justify_content_left>
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col> Complying with the laws and statutory requirements of various authorities.</Col>
                        </Row>
                        <Row className="homesecondsecbullets mt-2" justify_content_left>
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col> Rendering personalised services to cater to individual requirements.</Col>
                        </Row>
                        <Row className="homesecondsecbullets mt-2" justify_content_left>
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col> Providing value based services from domestic and international perspective.</Col>
                        </Row>
                        <Row className="homesecondsecbullets mt-2" justify_content_left>
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col> Performing all assignments economically and without compromising on quality of services.</Col>
                        </Row>
                        <Row className="homesecondsecbullets mt-2" justify_content_left>
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col> On time delivery is one of our firm's policy. This is achieved through efficient and ethical work environment.</Col>
                        </Row>
                        <Row className="homesecondsecbullets mt-2" justify_content_left>
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col> Rendering personalised services to cater to individual requirements.</Col>
                        </Row>

                        <Col sm className="mt-4">
                            <div className="homesecondsecbullets" justify_content_left>
                                <FaCheck className="homesecondsectick" /> High Savings Potential
                            </div>
                            <div className="homesecondsecbullets mt-3" justify_content_left>
                                <FaCheck className="homesecondsectick" /> Professional Team
                            </div>
                            <div className="homesecondsecbullets mt-3" justify_content_left>
                                <FaCheck className="homesecondsectick" /> 24/7 customer support
                            </div>
                            <div className="homesecondsecbullets mt-3" justify_content_left>
                                <FaCheck className="homesecondsectick" /> Learn from customer feedback
                            </div>
                        </Col>
                    </Row>

                    <Row className="homesecondsecdesc mt-4 mx-2">
                        Facilities :
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Row className="homesecondsecbullets" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col> Expert advisor</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col> High saving potential</Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="homesecondsecbullets" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col> Professional team</Col>
                            </Row>
                            <Row className="homesecondsecbullets mt-3" justify_content_left>
                                <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                                <Col> 24/7 support</Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="homesecondsecdesc mt-4 mx-2">
                        We are a technology savvy team, and are equipped with the latest technology and communication system. We serve our business presence across India by the way of strategic alliances and networking with fellow professional firms to ensure timely deliverables to our valuable clients.
                    </Row>

                </Col>

                <Col className=" justify_content_center ">
                    <Row data-aos="fade-left" className="weprovideright justify_content_center">
                        <img src={aboutimg} alt="" className="aboutusimg " data-aos="fade-left" />
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row> */}

            <Row className="footermargin  mt-5">
                <Footer />
            </Row>

        </div >
    )
}

export default Aboutus