import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navigation from "./Navigation"
import { Row, Col, Modal } from "react-bootstrap"
import Agricultural_Drone_Patent from "./Images/Patent/Agricultural_Drone_Patent.jpg"
import Black_Board_Cleaning_Frame_Patent from "./Images/Patent/Black_Board_Cleaning_Frame_Patent.jpg"
import Head_Worn_Fall_Indicating_Device_Patent from "./Images/Patent/Head_Worn_Fall_Indicating_Device_Patent.jpeg"

const Patents = () => {

    const [showcarousel, setShowcarousel] = useState(false)
    const [modalimg, setModalimg] = useState(0)

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Modal
                size="lg"
                show={showcarousel}
                onHide={() => setShowcarousel(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Row>
                    <img
                        className=""
                        src={modalimg}
                        alt=""
                    />
                </Row>
            </Modal>

            <Row className=" patetnt_topbg justify_content_center">
                <Col className="">
                    <Row className="justify_content_center aboutheadingtxt">
                        Our Patents
                    </Row>

                    <Row className="justify_content_center ">
                        <Col className="justify_content_center ">

                            <span className="aboutustoptext1 mr-0"><Link to="/" className="aboutustoptext1">Home</Link></span>
                            <span className="aboutustoptext1 mx-3 mr-3">{">"}</span>
                            <span className="aboutustoptext2 ml-0">Our Patents</span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-5" >
                <Col xs={1}></Col>
                <Col >
                    <Row className="event2000thead justify-content-center">
                        Patents
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        A patent is an exclusive right granted for an invention, which is a product or a process that provides, in general, a new way of doing something, or offers a new technical solution to a problem. Here in SSIPMT IDEA Lab we not only do invention but we also motivate our students towards invention.
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        Following services are provided by us fro your patents
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        <ol>
                            <li>Seminars on Intellectual Property Rights.</li>
                            <li>Guidance on Patent Filing and registration.</li>
                            <li>Hand Holding</li>
                        </ol>
                    </Row>
                    <Row className="homefinmgmttext mt-3">
                        So if you have any Idea in your mind we can help you to convert that into a prototype. Further we can assist you to file patent for the same.
                    </Row>
                    <Row className="homefinmgmttext mt-4">
                        Some of our patents are:
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className=" justify_content_center mt-5 mb-5 " >
                <Col sm={1}></Col>
                <Col sm className="bloglistcenterborder">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={Agricultural_Drone_Patent} className="patentimg" alt=""
                                    onClick={() => {
                                        setShowcarousel(true)
                                        setModalimg(Agricultural_Drone_Patent)
                                    }}
                                />
                            </Row>
                            <Row className=" mx-5">
                                <Row className="patenttitle justify_content_center mt-3">
                                    Agricultural Drone
                                </Row>
                                {/* <Row className="bloglistdesc mt-3">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                                    molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                                    numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                                    optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
                                    obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
                                    nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,
                                    tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
                                    quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos
                                    sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
                                    recusandae alias error harum maxime adipisci amet laborum. Perspiciatis
                                    minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit
                                    quibusdam sed amet tempora.

                                </Row> */}

                                {/* <Row className="bloglistreadmore mt-3" onClick={() => setShowcertificate1(true)}>
                                        Read more ->
                                </Row> */}

                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm className=""></Col>
                <Col sm={1}></Col>
            </Row>

            <Row className=" justify_content_center mt-5 mb-5 " >
                <Col sm={1}></Col>
                <Col sm className="bloglistcenterborder"></Col>
                <Col sm className="">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={Black_Board_Cleaning_Frame_Patent} className="patentimg" alt=""
                                    onClick={() => {
                                        setShowcarousel(true)
                                        setModalimg(Black_Board_Cleaning_Frame_Patent)
                                    }}
                                />
                            </Row>
                            <Row className=" mx-5">
                                <Row className="patenttitle justify_content_center mt-3">
                                    Black Board Cleaning Frame
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>

            <Row className=" justify_content_center mt-5 mb-5 " >
                <Col sm={1}></Col>
                <Col sm className="bloglistcenterborder">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={Head_Worn_Fall_Indicating_Device_Patent} className="patentimg" alt=""
                                    onClick={() => {
                                        setShowcarousel(true)
                                        setModalimg(Head_Worn_Fall_Indicating_Device_Patent)
                                    }}
                                />
                            </Row>
                            <Row className=" mx-5">
                                <Row className="patenttitle justify_content_center mt-3">
                                    Head Worn Fall Indicating Device
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm className=""></Col>
                <Col sm={1}></Col>
            </Row>

            <Row className="footermargin  ">
                <Footer />
            </Row>

        </div>
    )
}

export default Patents