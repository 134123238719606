import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navigation from "./Navigation"
import { Row, Col, Modal } from "react-bootstrap"
import img_3D_Printing from "./Images/Facilities/3D Printing.jpeg"
import img_3D_scanner from "./Images/Facilities/3D Scanner.jpg"
// import Aerial_Robotics from "./Images/Facilities/Aerial Robotics.jpeg"
import CNC_Laser_Cutter from "./Images/Facilities/CNC Laser Cutter.jpeg"
import CNC_Wood_Router from "./Images/Facilities/CNC Wood Router.jpeg"
import Sublimation from "./Images/Facilities/Sublimation Printing at IDEA Lab.jpeg"
import Mechanical_Handtools from "./Images/Facilities/Mechanical Handtools.jpeg"
import Soldering_Stations from "./Images/Facilities/Soldering Stations.jpeg"

const Facilities = () => {

    // const [showcertificate, setShowcertificate] = useState(false)

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className=" facilities_topbg justify_content_center">
                <Col className="">
                    <Row className="justify_content_center aboutheadingtxt">
                        Facilities
                    </Row>

                    <Row className="justify_content_center ">
                        <Col className="justify_content_center ">
                            <span className="aboutustoptext1 mr-0"><Link to="/" className="aboutustoptext1">Home</Link></span>
                            <span className="aboutustoptext1 mx-3 mr-3">{">"}</span>
                            <span className="aboutustoptext2 ml-0">Facilities</span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="eventmobmargin justify_content_center mt-5 " >
                <Col xs={1}></Col>
                <Col sm={6} className="">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={img_3D_Printing} className="facilities_img" alt=""
                                />
                            </Row>

                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className="" >
                    {/* <Col sm={3} className="" style={{ display: "grid", alignContent: "center", textAlign:"right" }}> */}
                    <Row className=" mt-5">
                        <Col>
                            <Row className="event2000thead justify_content_center" >
                                3D Printing
                            </Row>
                        </Col>
                        <Row className="bloglistdesc mt-3">
                            3D printing is a process that uses computer-aided design, or CAD, to create objects layer by layer. 3D printing is commonly used in manufacturing and automotive industries, where tools and parts are made using 3D printers.
                        </Row>
                        <Row className="bloglistdesc mt-3">
                            Here in IDEA Lab we have 3 Flash Forge built 3D Printers namely Flash forge Guider 2, Flash Forge Adventurer 4 and Flash Forge Adventurer 3. It has a size build volume of  150x150x150 mm. It has a precision of 0.1-0.2 mm and printing thickness of 0.1-0.4mm. PLA and ABS are the material compatible with this printer.
                        </Row>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="eventmobmargin  mt-5" >
                <Col xs={1}></Col>
                <Col sm={4} className="mt-5" >
                    <Row className="">
                        <Row className="event2000thead justify_content_center mt-3">
                            3D Scanner
                        </Row>
                        <Row className="bloglistdesc mt-3">
                            The CR-Scan is simple and straightforward to use and can be used immediately, both without calibrations and without markings. The device can be connected to the computer via USB and is compatible with Windows, Android and Mac operating systems.
                        </Row>
                        <Row className="bloglistdesc mt-3">
                            Here in IDEA Lab we have a Creality make 3D Scanner with scanning area of 536 x 738 mm. Objects are captured by the scanner with an accuracy of up to 0.1 mm. The 3D scanner offers numerous powerful model processing methods such as:
                        </Row>
                        <Row className="bloglistdesc ">
                            <ul>
                                <li className="mt-3">Model editing</li>
                                <li className="mt-3">Automatic fill and repair</li>
                                <li className="mt-3">Automatic colour repair</li>
                                <li className="mt-3">Automatic substrate recognition</li>
                                <li className="mt-3">Non-destructive network simplification</li>
                                <li className="mt-3">Automatic soft noise removal</li>
                            </ul>

                        </Row>
                    </Row>
                </Col>
                <Col sm={6} className="">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={img_3D_scanner} className="facilities_img" alt=""
                                />
                            </Row>

                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="eventmobmargin justify_content_center mt-5 " >
                <Col xs={1}></Col>
                <Col sm={6} className="">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={CNC_Laser_Cutter} className="facilities_img" alt=""
                                />
                            </Row>

                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className="mt-5" >
                    <Row className="justify_content_center ">
                        <Col>
                            <Row className="event2000thead justify_content_center" >
                                CNC Laser Cutter
                            </Row>
                            <Row className="bloglistdesc mt-3">
                                A CNC laser cutter is a piece of computer numerical control (CNC) equipment that uses a focused, high-powered laser beam to mark, cut, or engrave a material to form custom shapes. Its unique design and operation make it highly accurate, especially when cutting intricate shapes and small holes.
                            </Row>
                            <Row className="bloglistdesc mt-3">
                                Here in IDEA Lab we have a Mehta company built CNC laser cutter EVA-II-43 with a cutting area of 4ft x 3ft. It has a travelling speed of 600mm/s with a minimum letter size of 1mm. This can work on MDF Board, Acrylic, Leather and Fabrics.
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="eventmobmargin  mt-5 " >
                <Col xs={1}></Col>
                <Col sm={4} className="mt-5" >
                    <Row className="">
                        <Row className="event2000thead justify_content_center mt-3">
                            CNC Wood Router
                        </Row>
                        <Row className="bloglistdesc mt-3">
                            A CNC router can be used to produce items such as door carvings, interior and exterior decorations, wood panels, sign boards, wooden frames, moldings, musical instruments, furniture etc.
                        </Row>
                        <Row className="bloglistdesc mt-3">
                            Here in IDEA Lab we have a Mehta company built CNC Router RX 1325. It has a working area of 1300 x 2500 x 200 mm with a spindle speed of 18000 RPM and maximum travelling speed of 25 mtr/min.
                        </Row>
                    </Row>
                </Col>
                <Col sm={6} className="">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={CNC_Wood_Router} className="facilities_img" alt=""
                                />
                            </Row>

                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="eventmobmargin justify_content_center mt-5" >
                <Col xs={1}></Col>
                <Col sm={6} className="">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={Soldering_Stations} className="facilities_img" alt=""
                                />
                            </Row>

                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className="mt-5" >
                    <Row className="justify_content_center ">
                        <Col>
                            <Row className="event2000thead justify_content_center" >
                                PCB Designing Set-up
                            </Row>
                            <Row className="bloglistdesc mt-3">
                                APrinted circuit board (PCB) design brings your electronic circuits to life in the physical form. Using layout software, the PCB design process combines component placement and routing to define electrical connectivity on a manufactured circuit board.
                            </Row>
                            <Row className="bloglistdesc mt-3">
                                Here in IDEA Lab we have PCB Impression setup, PCB Etching and Drilling and Soldering Stations to facilitate PCB Designing process.
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="eventmobmargin  mt-5 " >
                <Col sm={1}></Col>
                <Col sm={4} className="mt-5" >
                    <Row className="">
                        <Row className="event2000thead justify_content_center mt-3">
                            Sublimation Printing Set-up
                        </Row>
                        <Row className="bloglistdesc mt-3">
                            A specific process of printing that first involves printing onto a special sheet of paper, then transferring that image onto another material. The ink is then heated until it disintegrates into the material.
                        </Row>
                        <Row className="bloglistdesc mt-3">
                            Here in IDEA Lab we have a Epson built sublimation printer with a 4 in 1 Submilation heating press machine in which we can print on items like Mug, Bottles, Tshirt, Tiles, Jigsaw Puzzle, Tea coaster and many more.
                        </Row>
                    </Row>
                </Col>
                <Col sm={6} className="">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={Sublimation} className="facilities_img" alt=""
                                />
                            </Row>

                        </Col>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="eventmobmargin justify_content_center mt-5 mb-5" >
                <Col xs={1}></Col>
                <Col sm={6} className="">
                    <Row className="justify_content_center">
                        <Col className="bloglistcard">
                            <Row className="justify_content_center">
                                <img src={Mechanical_Handtools} className="facilities_img" alt=""
                                />
                            </Row>

                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className="mt-5" >
                    <Row className="justify_content_center ">
                        <Col>
                            <Row className="event2000thead justify_content_center" >
                                Mechanical Handtools
                            </Row>
                            {/* <Row className="bloglistdesc mt-3">
                            A CNC router can be used to produce items such as door carvings, interior and exterior decorations, wood panels, sign boards, wooden frames, moldings, musical instruments, furniture etc.
                        </Row>
                        <Row className="bloglistdesc mt-3">
                            Here in IDEA Lab we have a Mehta company built CNC Router RX 1325. It has a working area of 1300 x 2500 x 200 mm with a spindle speed of 18000 RPM and maximum travelling speed of 25 mtr/min.
                        </Row> */}
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="footermargin  ">
                <Footer />
            </Row>

        </div>
    )
}

export default Facilities