import React, { useEffect } from 'react'
import Footer from "./Footer"
import Navigation from "./Navigation"
import { Row, Col } from "react-bootstrap"
import Technical_Guru_1 from "./Images/Team/Core Members/Technical Guru- Mr Pankaj Yadav.jpeg"
import Technical_Guru_2 from "./Images/Team/Core Members/Technical Guru- Mr Arun Kumar.jpeg"
import Dronevideo1 from "./Images/Drone_Pics_&_Videos/Dronevideo1.mp4"
import Dronevideo2 from "./Images/Drone_Pics_&_Videos/Dronevideo2.mp4"
import Dronevideo3 from "./Images/Drone_Pics_&_Videos/Dronevideo3.mp4"
import Dronevideo4 from "./Images/Drone_Pics_&_Videos/Dronevideo4.mp4"
import d1 from "./Images/Drone_Pics_&_Videos/D1.jpg"
import d2 from "./Images/Drone_Pics_&_Videos/D2.jpg"
import d3 from "./Images/Drone_Pics_&_Videos/D3.jpg"
import d4 from "./Images/Drone_Pics_&_Videos/D4.jpeg"
import d5 from "./Images/Drone_Pics_&_Videos/D5.jpeg"
import d6 from "./Images/Drone_Pics_&_Videos/D6.jpeg"
import d7 from "./Images/Drone_Pics_&_Videos/D7.jpeg"
import d8 from "./Images/Drone_Pics_&_Videos/D8.jpeg"
import d9 from "./Images/Drone_Pics_&_Videos/D9.jpeg"
import d10 from "./Images/Drone_Pics_&_Videos/D10.jpeg"
import d11 from "./Images/Drone_Pics_&_Videos/D11.jpeg"
import d3_without_bg from "./Images/Drone_Pics_&_Videos/D3-removebg-preview.png"
import { FaCheck } from "react-icons/fa"

const Drone = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>
            <Row className="dronepagtopbg mt-5">
                <Col xs={1}></Col>
                <Col className="" style={{ display: "grid", alignContent: "center" }}>
                    <Row className="dronepagehead justify_content_center">
                        CORE COMPETENCY – AERIAL ROBOTICS
                    </Row>
                    <Row className="droneuspdesc1 justify_content_center mt-3">
                        We deal in selling and training of all types of Drones and Aeromodeling.
                    </Row>

                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className=" mt-0 ">
                <Col xs={1}></Col>
                <Col sm={4} className="justify_content_center">
                    <img src={d3_without_bg} alt="" className="hompagdroneimgs"
                    />
                </Col>
                <Col sm className="" >
                    <Row>
                        <Row className="mt-5">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="dronepagedesc justify_content_left" >Photography/Cinematography Drones</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="dronepagedesc justify_content_left" >Payload/Delivery Drones</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="dronepagedesc justify_content_left" >Agricultural drones</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="dronepagedesc justify_content_left" >Sports/Racing Drones</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="dronepagedesc justify_content_left" >FPV Drones</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="dronepagedesc justify_content_left" >Autonomous Drone</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="dronepagedesc justify_content_left" >Spy Drone</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="dronepagedesc justify_content_left" >Micro-drone</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={1}><FaCheck className="homesecondsectick" /></Col>
                            <Col xs className="dronepagedesc justify_content_left" >Fixed Wings – Glider RC Plane, Top wing RC Plane and Cargo RC Plane</Col>
                        </Row>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="hompagdronebg mt-5">
                <Col xs={1}></Col>
                <Col sm className="" style={{ display: "grid", alignContent: "center" }}>
                    <Row className="dronepitlotphead justify_content_center">
                        Our Directorate General of Civil Aviation (DGCA) Approved Drone Instructor Pilots
                    </Row>
                    <Row className="  mt-5 mb-0">
                        <Col xs>
                            <Row className="justify_content_center">
                                <img src={Technical_Guru_1} alt="" className="teamimg" />
                            </Row>
                            <Row className="Teammebnamewhite justify_content_center mt-4">Mr. Pankaj Yadav</Row>
                            <Row className="Teammebdesignwhite justify_content_center mt-1">Guru</Row>
                            <Row className="Teammebdesignwhite justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                        </Col>
                        <Col xs>
                            <Row className="justify_content_center">
                                <img src={Technical_Guru_2} alt="" className="teamimg" />
                            </Row>
                            <Row className="Teammebnamewhite justify_content_center mt-4">Mr. Arun Kumar</Row>
                            <Row className="Teammebdesignwhite justify_content_center mt-1">Guru</Row>
                            <Row className="Teammebdesignwhite justify_content_center mt-1">SSIPMT IDEA Lab</Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="eventmobmargin mt-5 ">
                <Col xs={1}></Col>
                <Col xs className=''>
                    <Row className=''>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <video  controls="true" controlsList="nodownload"
                                    className="dronevideo"
                                >
                                    <source src={Dronevideo1} type="video/mp4" />
                                </video>
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <video  controls="true" controlsList="nodownload"
                                    className="dronevideo"
                                >
                                    <source src={Dronevideo2} type="video/mp4" />
                                </video>
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <video  controls="true" controlsList="nodownload"
                                    className="dronevideo"
                                >
                                    <source src={Dronevideo3} type="video/mp4" />
                                </video>
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <video  controls="true" controlsList="nodownload"
                                    className="dronevideo"
                                >
                                    <source src={Dronevideo4} type="video/mp4" />
                                </video>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="eventmobmargin mt-5 ">
                <Col xs={1}></Col>
                <Col xs className=''>
                    <Row className=''>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <img src={d1} alt="" className='dronepageimg' />
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <img src={d2} alt="" className='dronepageimg' />
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <img src={d3} alt="" className='dronepageimg' />
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <img src={d4} alt="" className='dronepageimg' />
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <img src={d5} alt="" className='dronepageimg' />
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <img src={d6} alt="" className='dronepageimg' />
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <img src={d7} alt="" className='dronepageimg' />
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <img src={d8} alt="" className='dronepageimg' />
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <img src={d9} alt="" className='dronepageimg' />
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <img src={d10} alt="" className='dronepageimg' />
                            </Row>
                        </Col>
                        <Col sm={6} className='mt-5'>
                            <Row className='justify-content-center'>
                                <img src={d11} alt="" className='dronepageimg   ' />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="footermargin  mt-5">
                <Footer />
            </Row>

        </div >
    )
}

export default Drone