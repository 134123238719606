import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Navbar, Nav } from "react-bootstrap"
import logo from "./Images/SSIPMT_Idea_lab.png"
//import logo from "./Images/Ishaan_imgs/beamslogonew.png"
import { RiArrowDropDownLine } from "react-icons/ri"
import { FaUserAlt } from "react-icons/fa"

const Navigation = () => {

    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 550) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };

    window.addEventListener('scroll', changeNavbarColor);



    return (

        <div className="">

            <Navbar bg="" variant="" expand="sm" fixed="top" className='navbg_white mb-5'>
                <Link to="/">
                    <img src={logo} alt="beamslogo" className="beamslogonew" />
                </Link>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Link to="/" className='nav-link-black mt-1'>Home</Link>
                        <Link to="/Aboutus" className='nav-link-black mt-1'>About Us</Link>
                        <Link to="/Drone" className='nav-link-black mt-1'>Drone</Link>
                        {/* <Link to="/Team" className='nav-link-black mt-1'>Our Team</Link> */}
                        <Link to="/Patents" className='nav-link-black mt-1'>Patents</Link>
                        <Link to="/Projects" className='nav-link-black mt-1'>Projects</Link>
                        <Link to="" className="nav-link-black navigation_dropdown mt-0" >Events{""}<RiArrowDropDownLine style={{ fontSize: "30px" }} />
                            <div className="dropdown-content">
                                <Link to="/" className="nav-link-black dropdown_menu">Upcoming Events</Link>
                                <Link to="/Events" className="nav-link-black">Events in 2022</Link>
                                {/* <Link to="/" className="nav-link-black">Trainings provided </Link> */}
                                <Link to="/Mediacoverage" className="nav-link-black">Media coverage </Link>
                            </div>
                        </Link>
                        <Link to="/Facilities" className='nav-link-black mt-1'>Facilities </Link>
                        <Link to="/Achievement" className='nav-link-black mt-1'>Student Achievements </Link> 
                        <Link to="/Guests" className='nav-link-black mt-1'>Our Guests </Link>
                        {/* <Link to="/" className='nav-link-black mt-1'>Services </Link> */}
                        {/* <Link to="/Gallery" className='nav-link-black mt-1'>Gallery </Link> */}
                        {/* <Link to="" className="nav-link-black navigation_dropdown" >Pages{""}<RiArrowDropDownLine style={{ fontSize: "30px" }} />
                            <div className="dropdown-content">
                                <Link to="/" className="nav-link-black">Our Team</Link>
                                <Link to="/" className="nav-link-black">Pricing</Link>
                                <Link to="/" className="nav-link-black">FAQ</Link>
                                <Link to="/" className="nav-link-black">Events</Link>
                            </div>
                        </Link> */}


                        <Link to="/Contactus" className='nav-link-black mt-1'>Contact Us</Link>
                        {/* <Link to="" className=" ">
                            <FaUserAlt className="navuser_black" />
                        </Link> */}
                    </Nav>



                </Navbar.Collapse>

            </Navbar>





        </div >
    )
}

export default Navigation