import React, { useState, useEffect } from "react"
import { Row, Col, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
// import Widget from './Widget';
import Navigation from "./Navigation"
import Dr_Bhupesh_Mishra_Dr_K_K_Goel_2 from "./Images/Guests/Dr_Bhupesh_Mishra_Dr_K_K_Goel_2.jpeg"
import Dr_Neeraj_Saxena from "./Images/Guests/Dr_Neeraj_Saxena.jpeg"
import Dr_Shankar_Goenka1 from "./Images/Guests/Dr_Shankar_Goenka1.jpg"
import Mr_Chanchal_Mukherjee from "./Images/Guests/Mr_Chanchal_Mukherjee.JPG"
import Mr_Lalit_Chacko1 from "./Images/Guests/Mr_Lalit_Chacko1.jpeg"
import Mr_Pallaw_Sharma from "./Images/Guests/Mr_Pallaw_Sharma.jpg"
import Mr_Sanjay_Gupta_Mr_Debashish_Das from "./Images/Guests/Mr_Sanjay_Gupta_Mr_Debashish_Das.jpeg"
import Prof_Young_Su_Chung from "./Images/Guests/Prof_Young_Su_Chung.JPG"
import dividerimg from "./Images/divider.png"
import Footer from "./Footer"

const Guest = () => {

    const [showcarousel, setShowcarousel] = useState(false)
    const [modalimg, setModalimg] = useState(0)

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            {/* <div>
                <Widget />
            </div> */}

            <Modal
                size="lg"
                show={showcarousel}
                onHide={() => setShowcarousel(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Row>
                    <img
                        className=""
                        src={modalimg}
                        alt=""
                    />
                </Row>
            </Modal>

            <Row className="mb-5">
                <Navigation />
            </Row>
            <Row className="mt-5"></Row>

            <Row className=" gallery_topbg justify_content_center">
                <Col className="">
                    <Row className="justify_content_center aboutheadingtxt">
                        Guest @ IDEA Lab
                    </Row>

                    <Row className="justify_content_center ">
                        <Col className="justify_content_center ">
                            <span className="aboutustoptext1 mr-0"><Link to="/" className="aboutustoptext1">Home</Link></span>
                            <span className="aboutustoptext1 mx-3 mr-3">{">"}</span>
                            <span className="aboutustoptext2 ml-0">Guest @ IDEA Lab</span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="homfinmgmthead justify_content_center mt-5">
                Our Guest
            </Row>
            <Row className=" justify_content_center mt-2">
                <img src={dividerimg} alt="" className="dividerimg" />
            </Row>
            <Row className="eventmobmargin mt-5">
                <Col xs={1}></Col>
                <Col sm>
                    <Row >
                        <Row className=" justify-content-center">
                            <div className="guestimgbg justify-content-center">
                                <img src={Dr_Neeraj_Saxena} alt="" className='guestimg ' />
                            </div>
                        </Row>
                        <Row className="guestname justify-content-center mt-3">
                            Dr. Neeraj Saxena, Advisor-IDC, AICTE
                        </Row>
                    </Row>
                </Col>
                <Col sm>
                    <Row >
                        <Row className=" justify-content-center">
                            <div className="guestimgbg justify-content-center">
                                <img src={Dr_Bhupesh_Mishra_Dr_K_K_Goel_2} alt="" className='guestimg ' />
                            </div>
                        </Row>
                        <Row className="guestname justify-content-center mt-3">
                            Dr. Bhupesh Mishra and Dr K K Goel, Officials from MSME
                        </Row>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="eventmobmargin mt-5">
                <Col xs={1}></Col>
                <Col sm>
                    <Row >
                        <Row className=" justify-content-center">
                            <div className="guestimgbg justify-content-center">
                                <img src={Mr_Pallaw_Sharma} alt="" className='guestimg ' />
                            </div>
                        </Row>
                        <Row className="guestname justify-content-center mt-3">
                            Mr. Pallaw Sharma, SVP, Chief Data and Analytics Officer, CISCO
                        </Row>
                    </Row>
                </Col>
                <Col sm>
                    <Row >
                        <Row className=" justify-content-center">
                            <div className="guestimgbg justify-content-center">
                                <img src={Mr_Chanchal_Mukherjee} alt="" className='guestimg ' />
                            </div>
                        </Row>
                        <Row className="guestname justify-content-center mt-3">
                            Mr. Chanchal Mukherjee, Director of Strategic Customer Partnerships, EMEA, Google
                        </Row>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="eventmobmargin mt-5">
                <Col xs={1}></Col>
                <Col sm>
                    <Row >
                        <Row className=" justify-content-center">
                            <div className="guestimgbg justify-content-center">
                                <img src={Mr_Lalit_Chacko1} alt="" className='guestimg ' />
                            </div>
                        </Row>
                        <Row className="guestname justify-content-center mt-3">
                            Mr Lalit Chacko, Lucid Plus- IT Solutions
                        </Row>
                    </Row>
                </Col>
                <Col sm>
                    <Row >
                        <Row className=" justify-content-center">
                            <div className="guestimgbg justify-content-center">
                                <img src={Mr_Sanjay_Gupta_Mr_Debashish_Das} alt="" className='guestimg ' />
                            </div>
                        </Row>
                        <Row className="guestname justify-content-center mt-3">
                            Mr. Sanjay Gupta, Chief Human Resources Officer, Godawari Power and Ispat Ltd. - Hira Group and Mr. Debashish Das, Human Resources Officer at Hira Group.
                        </Row>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="eventmobmargin mt-5">
                <Col xs={1}></Col>
                <Col sm>
                    <Row >
                        <Row className=" justify-content-center">
                            <div className="guestimgbg justify-content-center">
                                <img src={Dr_Shankar_Goenka1} alt="" className='guestimg ' />
                            </div>
                        </Row>
                        <Row className="guestname justify-content-center mt-3">
                            Dr. Shankar Goenka, Chief Architect & Managing Director, WOW Factors India, Author, Coach & TEDx Speaker
                        </Row>
                    </Row>
                </Col>
                <Col sm>
                    <Row >
                        <Row className=" justify-content-center">
                            <div className="guestimgbg justify-content-center">
                                <img src={Prof_Young_Su_Chung} alt="" className='guestimg ' />
                            </div>
                        </Row>
                        <Row className="guestname justify-content-center mt-3">
                            Prof. Young Su Chung – Director, I.M.E.I., South Korea
                        </Row>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Footer />
            </Row>
        </>
    )
}

export default Guest